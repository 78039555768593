var radius, type;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

/*
 Leaflet.draw 1.0.4, a plugin that adds drawing and editing tools to Leaflet powered maps.
 (c) 2012-2017, Jacob Toye, Jon West, Smartrak, Leaflet

 https://github.com/Leaflet/Leaflet.draw
 http://leafletjs.com
 */
!function (t, e, i) {
  function o(t, e) {
    for (; (t = t.parentElement) && !t.classList.contains(e););

    return t;
  }

  L.drawVersion = "1.0.4", L.Draw = {}, L.drawLocal = {
    draw: {
      toolbar: {
        actions: {
          title: "Cancel drawing",
          text: "Cancel"
        },
        finish: {
          title: "Finish drawing",
          text: "Finish"
        },
        undo: {
          title: "Delete last point drawn",
          text: "Delete last point"
        },
        buttons: {
          polyline: "Draw a polyline",
          polygon: "Draw a polygon",
          rectangle: "Draw a rectangle",
          circle: "Draw a circle",
          marker: "Draw a marker",
          circlemarker: "Draw a circlemarker"
        }
      },
      handlers: {
        circle: {
          tooltip: {
            start: "Click and drag to draw circle."
          },
          radius: "Radius"
        },
        circlemarker: {
          tooltip: {
            start: "Click map to place circle marker."
          }
        },
        marker: {
          tooltip: {
            start: "Click map to place marker."
          }
        },
        polygon: {
          tooltip: {
            start: "Click to start drawing shape.",
            cont: "Click to continue drawing shape.",
            end: "Click first point to close this shape."
          }
        },
        polyline: {
          error: "<strong>Error:</strong> shape edges cannot cross!",
          tooltip: {
            start: "Click to start drawing line.",
            cont: "Click to continue drawing line.",
            end: "Click last point to finish line."
          }
        },
        rectangle: {
          tooltip: {
            start: "Click and drag to draw rectangle."
          }
        },
        simpleshape: {
          tooltip: {
            end: "Release mouse to finish drawing."
          }
        }
      }
    },
    edit: {
      toolbar: {
        actions: {
          save: {
            title: "Save changes",
            text: "Save"
          },
          cancel: {
            title: "Cancel editing, discards all changes",
            text: "Cancel"
          },
          clearAll: {
            title: "Clear all layers",
            text: "Clear All"
          }
        },
        buttons: {
          edit: "Edit layers",
          editDisabled: "No layers to edit",
          remove: "Delete layers",
          removeDisabled: "No layers to delete"
        }
      },
      handlers: {
        edit: {
          tooltip: {
            text: "Drag handles or markers to edit features.",
            subtext: "Click cancel to undo changes."
          }
        },
        remove: {
          tooltip: {
            text: "Click on a feature to remove."
          }
        }
      }
    }
  }, L.Draw.Event = {}, L.Draw.Event.CREATED = "draw:created", L.Draw.Event.EDITED = "draw:edited", L.Draw.Event.DELETED = "draw:deleted", L.Draw.Event.DRAWSTART = "draw:drawstart", L.Draw.Event.DRAWSTOP = "draw:drawstop", L.Draw.Event.DRAWVERTEX = "draw:drawvertex", L.Draw.Event.EDITSTART = "draw:editstart", L.Draw.Event.EDITMOVE = "draw:editmove", L.Draw.Event.EDITRESIZE = "draw:editresize", L.Draw.Event.EDITVERTEX = "draw:editvertex", L.Draw.Event.EDITSTOP = "draw:editstop", L.Draw.Event.DELETESTART = "draw:deletestart", L.Draw.Event.DELETESTOP = "draw:deletestop", L.Draw.Event.TOOLBAROPENED = "draw:toolbaropened", L.Draw.Event.TOOLBARCLOSED = "draw:toolbarclosed", L.Draw.Event.MARKERCONTEXT = "draw:markercontext", L.Draw = L.Draw || {}, L.Draw.Feature = L.Handler.extend({
    initialize: function (t, e) {
      (this || _global)._map = t, (this || _global)._container = t._container, (this || _global)._overlayPane = t._panes.overlayPane, (this || _global)._popupPane = t._panes.popupPane, e && e.shapeOptions && (e.shapeOptions = L.Util.extend({}, (this || _global).options.shapeOptions, e.shapeOptions)), L.setOptions(this || _global, e);
      var i = L.version.split(".");
      1 === parseInt(i[0], 10) && parseInt(i[1], 10) >= 2 ? L.Draw.Feature.include(L.Evented.prototype) : L.Draw.Feature.include(L.Mixin.Events);
    },
    enable: function () {
      (this || _global)._enabled || (L.Handler.prototype.enable.call(this || _global), this.fire("enabled", {
        handler: (this || _global).type
      }), (this || _global)._map.fire(L.Draw.Event.DRAWSTART, {
        layerType: (this || _global).type
      }));
    },
    disable: function () {
      (this || _global)._enabled && (L.Handler.prototype.disable.call(this || _global), (this || _global)._map.fire(L.Draw.Event.DRAWSTOP, {
        layerType: (this || _global).type
      }), this.fire("disabled", {
        handler: (this || _global).type
      }));
    },
    addHooks: function () {
      var t = (this || _global)._map;
      t && (L.DomUtil.disableTextSelection(), t.getContainer().focus(), (this || _global)._tooltip = new L.Draw.Tooltip((this || _global)._map), L.DomEvent.on((this || _global)._container, "keyup", (this || _global)._cancelDrawing, this || _global));
    },
    removeHooks: function () {
      (this || _global)._map && (L.DomUtil.enableTextSelection(), (this || _global)._tooltip.dispose(), (this || _global)._tooltip = null, L.DomEvent.off((this || _global)._container, "keyup", (this || _global)._cancelDrawing, this || _global));
    },
    setOptions: function (t) {
      L.setOptions(this || _global, t);
    },
    _fireCreatedEvent: function (t) {
      (this || _global)._map.fire(L.Draw.Event.CREATED, {
        layer: t,
        layerType: (this || _global).type
      });
    },
    _cancelDrawing: function (t) {
      27 === t.keyCode && ((this || _global)._map.fire("draw:canceled", {
        layerType: (this || _global).type
      }), this.disable());
    }
  }), L.Draw.Polyline = L.Draw.Feature.extend({
    statics: {
      TYPE: "polyline"
    },
    Poly: L.Polyline,
    options: {
      allowIntersection: !0,
      repeatMode: !1,
      drawError: {
        color: "#b00b00",
        timeout: 2500
      },
      icon: new L.DivIcon({
        iconSize: new L.Point(8, 8),
        className: "leaflet-div-icon leaflet-editing-icon"
      }),
      touchIcon: new L.DivIcon({
        iconSize: new L.Point(20, 20),
        className: "leaflet-div-icon leaflet-editing-icon leaflet-touch-icon"
      }),
      guidelineDistance: 20,
      maxGuideLineLength: 4000,
      shapeOptions: {
        stroke: !0,
        color: "#3388ff",
        weight: 4,
        opacity: 0.5,
        fill: !1,
        clickable: !0
      },
      metric: !0,
      feet: !0,
      nautic: !1,
      showLength: !0,
      zIndexOffset: 2000,
      factor: 1,
      maxPoints: 0
    },
    initialize: function (t, e) {
      L.Browser.touch && ((this || _global).options.icon = (this || _global).options.touchIcon), (this || _global).options.drawError.message = L.drawLocal.draw.handlers.polyline.error, e && e.drawError && (e.drawError = L.Util.extend({}, (this || _global).options.drawError, e.drawError)), (this || _global).type = L.Draw.Polyline.TYPE, L.Draw.Feature.prototype.initialize.call(this || _global, t, e);
    },
    addHooks: function () {
      L.Draw.Feature.prototype.addHooks.call(this || _global), (this || _global)._map && ((this || _global)._markers = [], (this || _global)._markerGroup = new L.LayerGroup(), (this || _global)._map.addLayer((this || _global)._markerGroup), (this || _global)._poly = new L.Polyline([], (this || _global).options.shapeOptions), (this || _global)._tooltip.updateContent(this._getTooltipText()), (this || _global)._mouseMarker || ((this || _global)._mouseMarker = L.marker((this || _global)._map.getCenter(), {
        icon: L.divIcon({
          className: "leaflet-mouse-marker",
          iconAnchor: [20, 20],
          iconSize: [40, 40]
        }),
        opacity: 0,
        zIndexOffset: (this || _global).options.zIndexOffset
      })), (this || _global)._mouseMarker.on("mouseout", (this || _global)._onMouseOut, this || _global).on("mousemove", (this || _global)._onMouseMove, this || _global).on("mousedown", (this || _global)._onMouseDown, this || _global).on("mouseup", (this || _global)._onMouseUp, this || _global).addTo((this || _global)._map), (this || _global)._map.on("mouseup", (this || _global)._onMouseUp, this || _global).on("mousemove", (this || _global)._onMouseMove, this || _global).on("zoomlevelschange", (this || _global)._onZoomEnd, this || _global).on("touchstart", (this || _global)._onTouch, this || _global).on("zoomend", (this || _global)._onZoomEnd, this || _global));
    },
    removeHooks: function () {
      L.Draw.Feature.prototype.removeHooks.call(this || _global), this._clearHideErrorTimeout(), this._cleanUpShape(), (this || _global)._map.removeLayer((this || _global)._markerGroup), delete (this || _global)._markerGroup, delete (this || _global)._markers, (this || _global)._map.removeLayer((this || _global)._poly), delete (this || _global)._poly, (this || _global)._mouseMarker.off("mousedown", (this || _global)._onMouseDown, this || _global).off("mouseout", (this || _global)._onMouseOut, this || _global).off("mouseup", (this || _global)._onMouseUp, this || _global).off("mousemove", (this || _global)._onMouseMove, this || _global), (this || _global)._map.removeLayer((this || _global)._mouseMarker), delete (this || _global)._mouseMarker, this._clearGuides(), (this || _global)._map.off("mouseup", (this || _global)._onMouseUp, this || _global).off("mousemove", (this || _global)._onMouseMove, this || _global).off("zoomlevelschange", (this || _global)._onZoomEnd, this || _global).off("zoomend", (this || _global)._onZoomEnd, this || _global).off("touchstart", (this || _global)._onTouch, this || _global).off("click", (this || _global)._onTouch, this || _global);
    },
    deleteLastVertex: function () {
      if (!((this || _global)._markers.length <= 1)) {
        var t = (this || _global)._markers.pop(),
            e = (this || _global)._poly,
            i = e.getLatLngs(),
            o = i.splice(-1, 1)[0];

        (this || _global)._poly.setLatLngs(i), (this || _global)._markerGroup.removeLayer(t), e.getLatLngs().length < 2 && (this || _global)._map.removeLayer(e), this._vertexChanged(o, !1);
      }
    },
    addVertex: function (t) {
      if ((this || _global)._markers.length >= 2 && !(this || _global).options.allowIntersection && (this || _global)._poly.newLatLngIntersects(t)) return void this._showErrorTooltip();
      (this || _global)._errorShown && this._hideErrorTooltip(), (this || _global)._markers.push(this._createMarker(t)), (this || _global)._poly.addLatLng(t), 2 === (this || _global)._poly.getLatLngs().length && (this || _global)._map.addLayer((this || _global)._poly), this._vertexChanged(t, !0);
    },
    completeShape: function () {
      (this || _global)._markers.length <= 1 || !this._shapeIsValid() || (this._fireCreatedEvent(), this.disable(), (this || _global).options.repeatMode && this.enable());
    },
    _finishShape: function () {
      var t = (this || _global)._poly._defaultShape ? (this || _global)._poly._defaultShape() : (this || _global)._poly.getLatLngs(),
          e = (this || _global)._poly.newLatLngIntersects(t[t.length - 1]);

      if (!(this || _global).options.allowIntersection && e || !this._shapeIsValid()) return void this._showErrorTooltip();
      this._fireCreatedEvent(), this.disable(), (this || _global).options.repeatMode && this.enable();
    },
    _shapeIsValid: function () {
      return !0;
    },
    _onZoomEnd: function () {
      null !== (this || _global)._markers && this._updateGuide();
    },
    _onMouseMove: function (t) {
      var e = (this || _global)._map.mouseEventToLayerPoint(t.originalEvent),
          i = (this || _global)._map.layerPointToLatLng(e);

      (this || _global)._currentLatLng = i, this._updateTooltip(i), this._updateGuide(e), (this || _global)._mouseMarker.setLatLng(i), L.DomEvent.preventDefault(t.originalEvent);
    },
    _vertexChanged: function (t, e) {
      (this || _global)._map.fire(L.Draw.Event.DRAWVERTEX, {
        layers: (this || _global)._markerGroup
      }), this._updateFinishHandler(), this._updateRunningMeasure(t, e), this._clearGuides(), this._updateTooltip();
    },
    _onMouseDown: function (t) {
      if (!(this || _global)._clickHandled && !(this || _global)._touchHandled && !(this || _global)._disableMarkers) {
        this._onMouseMove(t), (this || _global)._clickHandled = !0, this._disableNewMarkers();
        var e = t.originalEvent,
            i = e.clientX,
            o = e.clientY;

        (this || _global)._startPoint.call(this || _global, i, o);
      }
    },
    _startPoint: function (t, e) {
      (this || _global)._mouseDownOrigin = L.point(t, e);
    },
    _onMouseUp: function (t) {
      var e = t.originalEvent,
          i = e.clientX,
          o = e.clientY;
      (this || _global)._endPoint.call(this || _global, i, o, t), (this || _global)._clickHandled = null;
    },
    _endPoint: function (e, i, o) {
      if ((this || _global)._mouseDownOrigin) {
        var a = L.point(e, i).distanceTo((this || _global)._mouseDownOrigin),
            n = this._calculateFinishDistance(o.latlng);

        (this || _global).options.maxPoints > 1 && (this || _global).options.maxPoints == (this || _global)._markers.length + 1 ? (this.addVertex(o.latlng), this._finishShape()) : n < 10 && L.Browser.touch ? this._finishShape() : Math.abs(a) < 9 * (t.devicePixelRatio || 1) && this.addVertex(o.latlng), this._enableNewMarkers();
      }

      (this || _global)._mouseDownOrigin = null;
    },
    _onTouch: function (t) {
      var e,
          i,
          o = t.originalEvent;
      !o.touches || !o.touches[0] || (this || _global)._clickHandled || (this || _global)._touchHandled || (this || _global)._disableMarkers || (e = o.touches[0].clientX, i = o.touches[0].clientY, this._disableNewMarkers(), (this || _global)._touchHandled = !0, (this || _global)._startPoint.call(this || _global, e, i), (this || _global)._endPoint.call(this || _global, e, i, t), (this || _global)._touchHandled = null), (this || _global)._clickHandled = null;
    },
    _onMouseOut: function () {
      (this || _global)._tooltip && (this || _global)._tooltip._onMouseOut.call((this || _global)._tooltip);
    },
    _calculateFinishDistance: function (t) {
      var e;

      if ((this || _global)._markers.length > 0) {
        var i;
        if ((this || _global).type === L.Draw.Polyline.TYPE) i = (this || _global)._markers[(this || _global)._markers.length - 1];else {
          if ((this || _global).type !== L.Draw.Polygon.TYPE) return 1 / 0;
          i = (this || _global)._markers[0];
        }

        var o = (this || _global)._map.latLngToContainerPoint(i.getLatLng()),
            a = new L.Marker(t, {
          icon: (this || _global).options.icon,
          zIndexOffset: 2 * (this || _global).options.zIndexOffset
        }),
            n = (this || _global)._map.latLngToContainerPoint(a.getLatLng());

        e = o.distanceTo(n);
      } else e = 1 / 0;

      return e;
    },
    _updateFinishHandler: function () {
      var t = (this || _global)._markers.length;
      t > 1 && (this || _global)._markers[t - 1].on("click", (this || _global)._finishShape, this || _global), t > 2 && (this || _global)._markers[t - 2].off("click", (this || _global)._finishShape, this || _global);
    },
    _createMarker: function (t) {
      var e = new L.Marker(t, {
        icon: (this || _global).options.icon,
        zIndexOffset: 2 * (this || _global).options.zIndexOffset
      });
      return (this || _global)._markerGroup.addLayer(e), e;
    },
    _updateGuide: function (t) {
      var e = (this || _global)._markers ? (this || _global)._markers.length : 0;
      e > 0 && (t = t || (this || _global)._map.latLngToLayerPoint((this || _global)._currentLatLng), this._clearGuides(), this._drawGuide((this || _global)._map.latLngToLayerPoint((this || _global)._markers[e - 1].getLatLng()), t));
    },
    _updateTooltip: function (t) {
      var e = this._getTooltipText();

      t && (this || _global)._tooltip.updatePosition(t), (this || _global)._errorShown || (this || _global)._tooltip.updateContent(e);
    },
    _drawGuide: function (t, e) {
      var i,
          o,
          a,
          n = Math.floor(Math.sqrt(Math.pow(e.x - t.x, 2) + Math.pow(e.y - t.y, 2))),
          s = (this || _global).options.guidelineDistance,
          r = (this || _global).options.maxGuideLineLength,
          l = n > r ? n - r : s;

      for ((this || _global)._guidesContainer || ((this || _global)._guidesContainer = L.DomUtil.create("div", "leaflet-draw-guides", (this || _global)._overlayPane)); l < n; l += (this || _global).options.guidelineDistance) i = l / n, o = {
        x: Math.floor(t.x * (1 - i) + i * e.x),
        y: Math.floor(t.y * (1 - i) + i * e.y)
      }, a = L.DomUtil.create("div", "leaflet-draw-guide-dash", (this || _global)._guidesContainer), a.style.backgroundColor = (this || _global)._errorShown ? (this || _global).options.drawError.color : (this || _global).options.shapeOptions.color, L.DomUtil.setPosition(a, o);
    },
    _updateGuideColor: function (t) {
      if ((this || _global)._guidesContainer) for (var e = 0, i = (this || _global)._guidesContainer.childNodes.length; e < i; e++) (this || _global)._guidesContainer.childNodes[e].style.backgroundColor = t;
    },
    _clearGuides: function () {
      if ((this || _global)._guidesContainer) for (; (this || _global)._guidesContainer.firstChild;) (this || _global)._guidesContainer.removeChild((this || _global)._guidesContainer.firstChild);
    },
    _getTooltipText: function () {
      var t,
          e,
          i = (this || _global).options.showLength;
      return 0 === (this || _global)._markers.length ? t = {
        text: L.drawLocal.draw.handlers.polyline.tooltip.start
      } : (e = i ? this._getMeasurementString() : "", t = 1 === (this || _global)._markers.length ? {
        text: L.drawLocal.draw.handlers.polyline.tooltip.cont,
        subtext: e
      } : {
        text: L.drawLocal.draw.handlers.polyline.tooltip.end,
        subtext: e
      }), t;
    },
    _updateRunningMeasure: function (t, e) {
      var i,
          o,
          a = (this || _global)._markers.length;
      1 === (this || _global)._markers.length ? (this || _global)._measurementRunningTotal = 0 : (i = a - (e ? 2 : 1), o = L.GeometryUtil.isVersion07x() ? t.distanceTo((this || _global)._markers[i].getLatLng()) * ((this || _global).options.factor || 1) : (this || _global)._map.distance(t, (this || _global)._markers[i].getLatLng()) * ((this || _global).options.factor || 1), (this || _global)._measurementRunningTotal += o * (e ? 1 : -1));
    },
    _getMeasurementString: function () {
      var t,
          e = (this || _global)._currentLatLng,
          i = (this || _global)._markers[(this || _global)._markers.length - 1].getLatLng();

      return t = L.GeometryUtil.isVersion07x() ? i && e && e.distanceTo ? (this || _global)._measurementRunningTotal + e.distanceTo(i) * ((this || _global).options.factor || 1) : (this || _global)._measurementRunningTotal || 0 : i && e ? (this || _global)._measurementRunningTotal + (this || _global)._map.distance(e, i) * ((this || _global).options.factor || 1) : (this || _global)._measurementRunningTotal || 0, L.GeometryUtil.readableDistance(t, (this || _global).options.metric, (this || _global).options.feet, (this || _global).options.nautic, (this || _global).options.precision);
    },
    _showErrorTooltip: function () {
      (this || _global)._errorShown = !0, (this || _global)._tooltip.showAsError().updateContent({
        text: (this || _global).options.drawError.message
      }), this._updateGuideColor((this || _global).options.drawError.color), (this || _global)._poly.setStyle({
        color: (this || _global).options.drawError.color
      }), this._clearHideErrorTimeout(), (this || _global)._hideErrorTimeout = setTimeout(L.Util.bind((this || _global)._hideErrorTooltip, this || _global), (this || _global).options.drawError.timeout);
    },
    _hideErrorTooltip: function () {
      (this || _global)._errorShown = !1, this._clearHideErrorTimeout(), (this || _global)._tooltip.removeError().updateContent(this._getTooltipText()), this._updateGuideColor((this || _global).options.shapeOptions.color), (this || _global)._poly.setStyle({
        color: (this || _global).options.shapeOptions.color
      });
    },
    _clearHideErrorTimeout: function () {
      (this || _global)._hideErrorTimeout && (clearTimeout((this || _global)._hideErrorTimeout), (this || _global)._hideErrorTimeout = null);
    },
    _disableNewMarkers: function () {
      (this || _global)._disableMarkers = !0;
    },
    _enableNewMarkers: function () {
      setTimeout(function () {
        (this || _global)._disableMarkers = !1;
      }.bind(this || _global), 50);
    },
    _cleanUpShape: function () {
      (this || _global)._markers.length > 1 && (this || _global)._markers[(this || _global)._markers.length - 1].off("click", (this || _global)._finishShape, this || _global);
    },
    _fireCreatedEvent: function () {
      var t = new (this || _global).Poly((this || _global)._poly.getLatLngs(), (this || _global).options.shapeOptions);

      L.Draw.Feature.prototype._fireCreatedEvent.call(this || _global, t);
    }
  }), L.Draw.Polygon = L.Draw.Polyline.extend({
    statics: {
      TYPE: "polygon"
    },
    Poly: L.Polygon,
    options: {
      showArea: !1,
      showLength: !1,
      shapeOptions: {
        stroke: !0,
        color: "#3388ff",
        weight: 4,
        opacity: 0.5,
        fill: !0,
        fillColor: null,
        fillOpacity: 0.2,
        clickable: !0
      },
      metric: !0,
      feet: !0,
      nautic: !1,
      precision: {}
    },
    initialize: function (t, e) {
      L.Draw.Polyline.prototype.initialize.call(this || _global, t, e), (this || _global).type = L.Draw.Polygon.TYPE;
    },
    _updateFinishHandler: function () {
      var t = (this || _global)._markers.length;
      1 === t && (this || _global)._markers[0].on("click", (this || _global)._finishShape, this || _global), t > 2 && ((this || _global)._markers[t - 1].on("dblclick", (this || _global)._finishShape, this || _global), t > 3 && (this || _global)._markers[t - 2].off("dblclick", (this || _global)._finishShape, this || _global));
    },
    _getTooltipText: function () {
      var t, e;
      return 0 === (this || _global)._markers.length ? t = L.drawLocal.draw.handlers.polygon.tooltip.start : (this || _global)._markers.length < 3 ? (t = L.drawLocal.draw.handlers.polygon.tooltip.cont, e = this._getMeasurementString()) : (t = L.drawLocal.draw.handlers.polygon.tooltip.end, e = this._getMeasurementString()), {
        text: t,
        subtext: e
      };
    },
    _getMeasurementString: function () {
      var t = (this || _global)._area,
          e = "";
      return t || (this || _global).options.showLength ? ((this || _global).options.showLength && (e = L.Draw.Polyline.prototype._getMeasurementString.call(this || _global)), t && (e += "<br>" + L.GeometryUtil.readableArea(t, (this || _global).options.metric, (this || _global).options.precision)), e) : null;
    },
    _shapeIsValid: function () {
      return (this || _global)._markers.length >= 3;
    },
    _vertexChanged: function (t, e) {
      var i;
      !(this || _global).options.allowIntersection && (this || _global).options.showArea && (i = (this || _global)._poly.getLatLngs(), (this || _global)._area = L.GeometryUtil.geodesicArea(i)), L.Draw.Polyline.prototype._vertexChanged.call(this || _global, t, e);
    },
    _cleanUpShape: function () {
      var t = (this || _global)._markers.length;
      t > 0 && ((this || _global)._markers[0].off("click", (this || _global)._finishShape, this || _global), t > 2 && (this || _global)._markers[t - 1].off("dblclick", (this || _global)._finishShape, this || _global));
    }
  }), L.SimpleShape = {}, L.Draw.SimpleShape = L.Draw.Feature.extend({
    options: {
      repeatMode: !1
    },
    initialize: function (t, e) {
      (this || _global)._endLabelText = L.drawLocal.draw.handlers.simpleshape.tooltip.end, L.Draw.Feature.prototype.initialize.call(this || _global, t, e);
    },
    addHooks: function () {
      L.Draw.Feature.prototype.addHooks.call(this || _global), (this || _global)._map && ((this || _global)._mapDraggable = (this || _global)._map.dragging.enabled(), (this || _global)._mapDraggable && (this || _global)._map.dragging.disable(), (this || _global)._container.style.cursor = "crosshair", (this || _global)._tooltip.updateContent({
        text: (this || _global)._initialLabelText
      }), (this || _global)._map.on("mousedown", (this || _global)._onMouseDown, this || _global).on("mousemove", (this || _global)._onMouseMove, this || _global).on("touchstart", (this || _global)._onMouseDown, this || _global).on("touchmove", (this || _global)._onMouseMove, this || _global), e.addEventListener("touchstart", L.DomEvent.preventDefault, {
        passive: !1
      }));
    },
    removeHooks: function () {
      L.Draw.Feature.prototype.removeHooks.call(this || _global), (this || _global)._map && ((this || _global)._mapDraggable && (this || _global)._map.dragging.enable(), (this || _global)._container.style.cursor = "", (this || _global)._map.off("mousedown", (this || _global)._onMouseDown, this || _global).off("mousemove", (this || _global)._onMouseMove, this || _global).off("touchstart", (this || _global)._onMouseDown, this || _global).off("touchmove", (this || _global)._onMouseMove, this || _global), L.DomEvent.off(e, "mouseup", (this || _global)._onMouseUp, this || _global), L.DomEvent.off(e, "touchend", (this || _global)._onMouseUp, this || _global), e.removeEventListener("touchstart", L.DomEvent.preventDefault), (this || _global)._shape && ((this || _global)._map.removeLayer((this || _global)._shape), delete (this || _global)._shape)), (this || _global)._isDrawing = !1;
    },
    _getTooltipText: function () {
      return {
        text: (this || _global)._endLabelText
      };
    },
    _onMouseDown: function (t) {
      (this || _global)._isDrawing = !0, (this || _global)._startLatLng = t.latlng, L.DomEvent.on(e, "mouseup", (this || _global)._onMouseUp, this || _global).on(e, "touchend", (this || _global)._onMouseUp, this || _global).preventDefault(t.originalEvent);
    },
    _onMouseMove: function (t) {
      var e = t.latlng;
      (this || _global)._tooltip.updatePosition(e), (this || _global)._isDrawing && ((this || _global)._tooltip.updateContent(this._getTooltipText()), this._drawShape(e));
    },
    _onMouseUp: function () {
      (this || _global)._shape && this._fireCreatedEvent(), this.disable(), (this || _global).options.repeatMode && this.enable();
    }
  }), L.Draw.Rectangle = L.Draw.SimpleShape.extend({
    statics: {
      TYPE: "rectangle"
    },
    options: {
      shapeOptions: {
        stroke: !0,
        color: "#3388ff",
        weight: 4,
        opacity: 0.5,
        fill: !0,
        fillColor: null,
        fillOpacity: 0.2,
        clickable: !0
      },
      showArea: !0,
      metric: !0
    },
    initialize: function (t, e) {
      (this || _global).type = L.Draw.Rectangle.TYPE, (this || _global)._initialLabelText = L.drawLocal.draw.handlers.rectangle.tooltip.start, L.Draw.SimpleShape.prototype.initialize.call(this || _global, t, e);
    },
    disable: function () {
      (this || _global)._enabled && ((this || _global)._isCurrentlyTwoClickDrawing = !1, L.Draw.SimpleShape.prototype.disable.call(this || _global));
    },
    _onMouseUp: function (t) {
      if (!(this || _global)._shape && !(this || _global)._isCurrentlyTwoClickDrawing) return void ((this || _global)._isCurrentlyTwoClickDrawing = !0);
      (this || _global)._isCurrentlyTwoClickDrawing && !o(t.target, "leaflet-pane") || L.Draw.SimpleShape.prototype._onMouseUp.call(this || _global);
    },
    _drawShape: function (t) {
      (this || _global)._shape ? (this || _global)._shape.setBounds(new L.LatLngBounds((this || _global)._startLatLng, t)) : ((this || _global)._shape = new L.Rectangle(new L.LatLngBounds((this || _global)._startLatLng, t), (this || _global).options.shapeOptions), (this || _global)._map.addLayer((this || _global)._shape));
    },
    _fireCreatedEvent: function () {
      var t = new L.Rectangle((this || _global)._shape.getBounds(), (this || _global).options.shapeOptions);

      L.Draw.SimpleShape.prototype._fireCreatedEvent.call(this || _global, t);
    },
    _getTooltipText: function () {
      var t,
          e,
          i,
          o = L.Draw.SimpleShape.prototype._getTooltipText.call(this || _global),
          a = (this || _global)._shape,
          n = (this || _global).options.showArea;

      return a && (t = (this || _global)._shape._defaultShape ? (this || _global)._shape._defaultShape() : (this || _global)._shape.getLatLngs(), e = L.GeometryUtil.geodesicArea(t), i = n ? L.GeometryUtil.readableArea(e, (this || _global).options.metric) : ""), {
        text: o.text,
        subtext: i
      };
    }
  }), L.Draw.Marker = L.Draw.Feature.extend({
    statics: {
      TYPE: "marker"
    },
    options: {
      icon: new L.Icon.Default(),
      repeatMode: !1,
      zIndexOffset: 2000
    },
    initialize: function (t, e) {
      (this || _global).type = L.Draw.Marker.TYPE, (this || _global)._initialLabelText = L.drawLocal.draw.handlers.marker.tooltip.start, L.Draw.Feature.prototype.initialize.call(this || _global, t, e);
    },
    addHooks: function () {
      L.Draw.Feature.prototype.addHooks.call(this || _global), (this || _global)._map && ((this || _global)._tooltip.updateContent({
        text: (this || _global)._initialLabelText
      }), (this || _global)._mouseMarker || ((this || _global)._mouseMarker = L.marker((this || _global)._map.getCenter(), {
        icon: L.divIcon({
          className: "leaflet-mouse-marker",
          iconAnchor: [20, 20],
          iconSize: [40, 40]
        }),
        opacity: 0,
        zIndexOffset: (this || _global).options.zIndexOffset
      })), (this || _global)._mouseMarker.on("click", (this || _global)._onClick, this || _global).addTo((this || _global)._map), (this || _global)._map.on("mousemove", (this || _global)._onMouseMove, this || _global), (this || _global)._map.on("click", (this || _global)._onTouch, this || _global));
    },
    removeHooks: function () {
      L.Draw.Feature.prototype.removeHooks.call(this || _global), (this || _global)._map && ((this || _global)._map.off("click", (this || _global)._onClick, this || _global).off("click", (this || _global)._onTouch, this || _global), (this || _global)._marker && ((this || _global)._marker.off("click", (this || _global)._onClick, this || _global), (this || _global)._map.removeLayer((this || _global)._marker), delete (this || _global)._marker), (this || _global)._mouseMarker.off("click", (this || _global)._onClick, this || _global), (this || _global)._map.removeLayer((this || _global)._mouseMarker), delete (this || _global)._mouseMarker, (this || _global)._map.off("mousemove", (this || _global)._onMouseMove, this || _global));
    },
    _onMouseMove: function (t) {
      var e = t.latlng;
      (this || _global)._tooltip.updatePosition(e), (this || _global)._mouseMarker.setLatLng(e), (this || _global)._marker ? (e = (this || _global)._mouseMarker.getLatLng(), (this || _global)._marker.setLatLng(e)) : ((this || _global)._marker = this._createMarker(e), (this || _global)._marker.on("click", (this || _global)._onClick, this || _global), (this || _global)._map.on("click", (this || _global)._onClick, this || _global).addLayer((this || _global)._marker));
    },
    _createMarker: function (t) {
      return new L.Marker(t, {
        icon: (this || _global).options.icon,
        zIndexOffset: (this || _global).options.zIndexOffset
      });
    },
    _onClick: function () {
      this._fireCreatedEvent(), this.disable(), (this || _global).options.repeatMode && this.enable();
    },
    _onTouch: function (t) {
      this._onMouseMove(t), this._onClick();
    },
    _fireCreatedEvent: function () {
      var t = new L.Marker.Touch((this || _global)._marker.getLatLng(), {
        icon: (this || _global).options.icon
      });

      L.Draw.Feature.prototype._fireCreatedEvent.call(this || _global, t);
    }
  }), L.Draw.CircleMarker = L.Draw.Marker.extend({
    statics: {
      TYPE: "circlemarker"
    },
    options: {
      stroke: !0,
      color: "#3388ff",
      weight: 4,
      opacity: 0.5,
      fill: !0,
      fillColor: null,
      fillOpacity: 0.2,
      clickable: !0,
      zIndexOffset: 2000
    },
    initialize: function (t, e) {
      (this || _global).type = L.Draw.CircleMarker.TYPE, (this || _global)._initialLabelText = L.drawLocal.draw.handlers.circlemarker.tooltip.start, L.Draw.Feature.prototype.initialize.call(this || _global, t, e);
    },
    _fireCreatedEvent: function () {
      var t = new L.CircleMarker((this || _global)._marker.getLatLng(), (this || _global).options);

      L.Draw.Feature.prototype._fireCreatedEvent.call(this || _global, t);
    },
    _createMarker: function (t) {
      return new L.CircleMarker(t, (this || _global).options);
    }
  }), L.Draw.Circle = L.Draw.SimpleShape.extend({
    statics: {
      TYPE: "circle"
    },
    options: {
      shapeOptions: {
        stroke: !0,
        color: "#3388ff",
        weight: 4,
        opacity: 0.5,
        fill: !0,
        fillColor: null,
        fillOpacity: 0.2,
        clickable: !0
      },
      showRadius: !0,
      metric: !0,
      feet: !0,
      nautic: !1
    },
    initialize: function (t, e) {
      (this || _global).type = L.Draw.Circle.TYPE, (this || _global)._initialLabelText = L.drawLocal.draw.handlers.circle.tooltip.start, L.Draw.SimpleShape.prototype.initialize.call(this || _global, t, e);
    },
    _drawShape: function (t) {
      if (L.GeometryUtil.isVersion07x()) var e = (this || _global)._startLatLng.distanceTo(t);else var e = (this || _global)._map.distance((this || _global)._startLatLng, t);
      (this || _global)._shape ? (this || _global)._shape.setRadius(e) : ((this || _global)._shape = new L.Circle((this || _global)._startLatLng, e, (this || _global).options.shapeOptions), (this || _global)._map.addLayer((this || _global)._shape));
    },
    _fireCreatedEvent: function () {
      var t = new L.Circle((this || _global)._startLatLng, (this || _global)._shape.getRadius(), (this || _global).options.shapeOptions);

      L.Draw.SimpleShape.prototype._fireCreatedEvent.call(this || _global, t);
    },
    _onMouseMove: function (t) {
      var e,
          i = t.latlng,
          o = (this || _global).options.showRadius,
          a = (this || _global).options.metric;

      if ((this || _global)._tooltip.updatePosition(i), (this || _global)._isDrawing) {
        this._drawShape(i), e = (this || _global)._shape.getRadius().toFixed(1);
        var n = "";
        o && (n = L.drawLocal.draw.handlers.circle.radius + ": " + L.GeometryUtil.readableDistance(e, a, (this || _global).options.feet, (this || _global).options.nautic)), (this || _global)._tooltip.updateContent({
          text: (this || _global)._endLabelText,
          subtext: n
        });
      }
    }
  }), L.Edit = L.Edit || {}, L.Edit.Marker = L.Handler.extend({
    initialize: function (t, e) {
      (this || _global)._marker = t, L.setOptions(this || _global, e);
    },
    addHooks: function () {
      var t = (this || _global)._marker;
      t.dragging.enable(), t.on("dragend", (this || _global)._onDragEnd, t), this._toggleMarkerHighlight();
    },
    removeHooks: function () {
      var t = (this || _global)._marker;
      t.dragging.disable(), t.off("dragend", (this || _global)._onDragEnd, t), this._toggleMarkerHighlight();
    },
    _onDragEnd: function (t) {
      var e = t.target;
      e.edited = !0, (this || _global)._map.fire(L.Draw.Event.EDITMOVE, {
        layer: e
      });
    },
    _toggleMarkerHighlight: function () {
      var t = (this || _global)._marker._icon;
      t && (t.style.display = "none", L.DomUtil.hasClass(t, "leaflet-edit-marker-selected") ? (L.DomUtil.removeClass(t, "leaflet-edit-marker-selected"), this._offsetMarker(t, -4)) : (L.DomUtil.addClass(t, "leaflet-edit-marker-selected"), this._offsetMarker(t, 4)), t.style.display = "");
    },
    _offsetMarker: function (t, e) {
      var i = parseInt(t.style.marginTop, 10) - e,
          o = parseInt(t.style.marginLeft, 10) - e;
      t.style.marginTop = i + "px", t.style.marginLeft = o + "px";
    }
  }), L.Marker.addInitHook(function () {
    L.Edit.Marker && ((this || _global).editing = new L.Edit.Marker(this || _global), (this || _global).options.editable && (this || _global).editing.enable());
  }), L.Edit = L.Edit || {}, L.Edit.Poly = L.Handler.extend({
    initialize: function (t) {
      (this || _global).latlngs = [t._latlngs], t._holes && ((this || _global).latlngs = (this || _global).latlngs.concat(t._holes)), (this || _global)._poly = t, (this || _global)._poly.on("revert-edited", (this || _global)._updateLatLngs, this || _global);
    },
    _defaultShape: function () {
      return L.Polyline._flat ? L.Polyline._flat((this || _global)._poly._latlngs) ? (this || _global)._poly._latlngs : (this || _global)._poly._latlngs[0] : (this || _global)._poly._latlngs;
    },
    _eachVertexHandler: function (t) {
      for (var e = 0; e < (this || _global)._verticesHandlers.length; e++) t((this || _global)._verticesHandlers[e]);
    },
    addHooks: function () {
      this._initHandlers(), this._eachVertexHandler(function (t) {
        t.addHooks();
      });
    },
    removeHooks: function () {
      this._eachVertexHandler(function (t) {
        t.removeHooks();
      });
    },
    updateMarkers: function () {
      this._eachVertexHandler(function (t) {
        t.updateMarkers();
      });
    },
    _initHandlers: function () {
      (this || _global)._verticesHandlers = [];

      for (var t = 0; t < (this || _global).latlngs.length; t++) (this || _global)._verticesHandlers.push(new L.Edit.PolyVerticesEdit((this || _global)._poly, (this || _global).latlngs[t], (this || _global)._poly.options.poly));
    },
    _updateLatLngs: function (t) {
      (this || _global).latlngs = [t.layer._latlngs], t.layer._holes && ((this || _global).latlngs = (this || _global).latlngs.concat(t.layer._holes));
    }
  }), L.Edit.PolyVerticesEdit = L.Handler.extend({
    options: {
      icon: new L.DivIcon({
        iconSize: new L.Point(8, 8),
        className: "leaflet-div-icon leaflet-editing-icon"
      }),
      touchIcon: new L.DivIcon({
        iconSize: new L.Point(20, 20),
        className: "leaflet-div-icon leaflet-editing-icon leaflet-touch-icon"
      }),
      drawError: {
        color: "#b00b00",
        timeout: 1000
      }
    },
    initialize: function (t, e, i) {
      L.Browser.touch && ((this || _global).options.icon = (this || _global).options.touchIcon), (this || _global)._poly = t, i && i.drawError && (i.drawError = L.Util.extend({}, (this || _global).options.drawError, i.drawError)), (this || _global)._latlngs = e, L.setOptions(this || _global, i);
    },
    _defaultShape: function () {
      return L.Polyline._flat ? L.Polyline._flat((this || _global)._latlngs) ? (this || _global)._latlngs : (this || _global)._latlngs[0] : (this || _global)._latlngs;
    },
    addHooks: function () {
      var t = (this || _global)._poly,
          e = t._path;
      t instanceof L.Polygon || (t.options.fill = !1, t.options.editing && (t.options.editing.fill = !1)), e && t.options.editing && t.options.editing.className && (t.options.original.className && t.options.original.className.split(" ").forEach(function (t) {
        L.DomUtil.removeClass(e, t);
      }), t.options.editing.className.split(" ").forEach(function (t) {
        L.DomUtil.addClass(e, t);
      })), t.setStyle(t.options.editing), (this || _global)._poly._map && ((this || _global)._map = (this || _global)._poly._map, (this || _global)._markerGroup || this._initMarkers(), (this || _global)._poly._map.addLayer((this || _global)._markerGroup));
    },
    removeHooks: function () {
      var t = (this || _global)._poly,
          e = t._path;
      e && t.options.editing && t.options.editing.className && (t.options.editing.className.split(" ").forEach(function (t) {
        L.DomUtil.removeClass(e, t);
      }), t.options.original.className && t.options.original.className.split(" ").forEach(function (t) {
        L.DomUtil.addClass(e, t);
      })), t.setStyle(t.options.original), t._map && (t._map.removeLayer((this || _global)._markerGroup), delete (this || _global)._markerGroup, delete (this || _global)._markers);
    },
    updateMarkers: function () {
      (this || _global)._markerGroup.clearLayers(), this._initMarkers();
    },
    _initMarkers: function () {
      (this || _global)._markerGroup || ((this || _global)._markerGroup = new L.LayerGroup()), (this || _global)._markers = [];

      var t,
          e,
          i,
          o,
          a = this._defaultShape();

      for (t = 0, i = a.length; t < i; t++) o = this._createMarker(a[t], t), o.on("click", (this || _global)._onMarkerClick, this || _global), o.on("contextmenu", (this || _global)._onContextMenu, this || _global), (this || _global)._markers.push(o);

      var n, s;

      for (t = 0, e = i - 1; t < i; e = t++) (0 !== t || L.Polygon && (this || _global)._poly instanceof L.Polygon) && (n = (this || _global)._markers[e], s = (this || _global)._markers[t], this._createMiddleMarker(n, s), this._updatePrevNext(n, s));
    },
    _createMarker: function (t, e) {
      var i = new L.Marker.Touch(t, {
        draggable: !0,
        icon: (this || _global).options.icon
      });
      return i._origLatLng = t, i._index = e, i.on("dragstart", (this || _global)._onMarkerDragStart, this || _global).on("drag", (this || _global)._onMarkerDrag, this || _global).on("dragend", (this || _global)._fireEdit, this || _global).on("touchmove", (this || _global)._onTouchMove, this || _global).on("touchend", (this || _global)._fireEdit, this || _global).on("MSPointerMove", (this || _global)._onTouchMove, this || _global).on("MSPointerUp", (this || _global)._fireEdit, this || _global), (this || _global)._markerGroup.addLayer(i), i;
    },
    _onMarkerDragStart: function () {
      (this || _global)._poly.fire("editstart");
    },
    _spliceLatLngs: function () {
      var t = this._defaultShape(),
          e = [].splice.apply(t, arguments);

      return (this || _global)._poly._convertLatLngs(t, !0), (this || _global)._poly.redraw(), e;
    },
    _removeMarker: function (t) {
      var e = t._index;
      (this || _global)._markerGroup.removeLayer(t), (this || _global)._markers.splice(e, 1), this._spliceLatLngs(e, 1), this._updateIndexes(e, -1), t.off("dragstart", (this || _global)._onMarkerDragStart, this || _global).off("drag", (this || _global)._onMarkerDrag, this || _global).off("dragend", (this || _global)._fireEdit, this || _global).off("touchmove", (this || _global)._onMarkerDrag, this || _global).off("touchend", (this || _global)._fireEdit, this || _global).off("click", (this || _global)._onMarkerClick, this || _global).off("MSPointerMove", (this || _global)._onTouchMove, this || _global).off("MSPointerUp", (this || _global)._fireEdit, this || _global);
    },
    _fireEdit: function () {
      (this || _global)._poly.edited = !0, (this || _global)._poly.fire("edit"), (this || _global)._poly._map.fire(L.Draw.Event.EDITVERTEX, {
        layers: (this || _global)._markerGroup,
        poly: (this || _global)._poly
      });
    },
    _onMarkerDrag: function (t) {
      var e = t.target,
          i = (this || _global)._poly,
          o = L.LatLngUtil.cloneLatLng(e._origLatLng);

      if (L.extend(e._origLatLng, e._latlng), i.options.poly) {
        var a = i._map._editTooltip;

        if (!i.options.poly.allowIntersection && i.intersects()) {
          L.extend(e._origLatLng, o), e.setLatLng(o);
          var n = i.options.color;
          i.setStyle({
            color: (this || _global).options.drawError.color
          }), a && a.updateContent({
            text: L.drawLocal.draw.handlers.polyline.error
          }), setTimeout(function () {
            i.setStyle({
              color: n
            }), a && a.updateContent({
              text: L.drawLocal.edit.handlers.edit.tooltip.text,
              subtext: L.drawLocal.edit.handlers.edit.tooltip.subtext
            });
          }, 1000);
        }
      }

      e._middleLeft && e._middleLeft.setLatLng(this._getMiddleLatLng(e._prev, e)), e._middleRight && e._middleRight.setLatLng(this._getMiddleLatLng(e, e._next)), (this || _global)._poly._bounds._southWest = L.latLng(1 / 0, 1 / 0), (this || _global)._poly._bounds._northEast = L.latLng(-1 / 0, -1 / 0);

      var s = (this || _global)._poly.getLatLngs();

      (this || _global)._poly._convertLatLngs(s, !0), (this || _global)._poly.redraw(), (this || _global)._poly.fire("editdrag");
    },
    _onMarkerClick: function (t) {
      var e = L.Polygon && (this || _global)._poly instanceof L.Polygon ? 4 : 3,
          i = t.target;
      this._defaultShape().length < e || (this._removeMarker(i), this._updatePrevNext(i._prev, i._next), i._middleLeft && (this || _global)._markerGroup.removeLayer(i._middleLeft), i._middleRight && (this || _global)._markerGroup.removeLayer(i._middleRight), i._prev && i._next ? this._createMiddleMarker(i._prev, i._next) : i._prev ? i._next || (i._prev._middleRight = null) : i._next._middleLeft = null, this._fireEdit());
    },
    _onContextMenu: function (t) {
      var e = t.target;
      (this || _global)._poly;
      (this || _global)._poly._map.fire(L.Draw.Event.MARKERCONTEXT, {
        marker: e,
        layers: (this || _global)._markerGroup,
        poly: (this || _global)._poly
      }), L.DomEvent.stopPropagation;
    },
    _onTouchMove: function (t) {
      var e = (this || _global)._map.mouseEventToLayerPoint(t.originalEvent.touches[0]),
          i = (this || _global)._map.layerPointToLatLng(e),
          o = t.target;

      L.extend(o._origLatLng, i), o._middleLeft && o._middleLeft.setLatLng(this._getMiddleLatLng(o._prev, o)), o._middleRight && o._middleRight.setLatLng(this._getMiddleLatLng(o, o._next)), (this || _global)._poly.redraw(), this.updateMarkers();
    },
    _updateIndexes: function (t, e) {
      (this || _global)._markerGroup.eachLayer(function (i) {
        i._index > t && (i._index += e);
      });
    },
    _createMiddleMarker: function (t, e) {
      var i,
          o,
          a,
          n = this._getMiddleLatLng(t, e),
          s = this._createMarker(n);

      s.setOpacity(0.6), t._middleRight = e._middleLeft = s, o = function () {
        s.off("touchmove", o, this || _global);
        var a = e._index;
        s._index = a, s.off("click", i, this || _global).on("click", (this || _global)._onMarkerClick, this || _global), n.lat = s.getLatLng().lat, n.lng = s.getLatLng().lng, this._spliceLatLngs(a, 0, n), (this || _global)._markers.splice(a, 0, s), s.setOpacity(1), this._updateIndexes(a, 1), e._index++, this._updatePrevNext(t, s), this._updatePrevNext(s, e), (this || _global)._poly.fire("editstart");
      }, a = function () {
        s.off("dragstart", o, this || _global), s.off("dragend", a, this || _global), s.off("touchmove", o, this || _global), this._createMiddleMarker(t, s), this._createMiddleMarker(s, e);
      }, i = function () {
        o.call(this || _global), a.call(this || _global), this._fireEdit();
      }, s.on("click", i, this || _global).on("dragstart", o, this || _global).on("dragend", a, this || _global).on("touchmove", o, this || _global), (this || _global)._markerGroup.addLayer(s);
    },
    _updatePrevNext: function (t, e) {
      t && (t._next = e), e && (e._prev = t);
    },
    _getMiddleLatLng: function (t, e) {
      var i = (this || _global)._poly._map,
          o = i.project(t.getLatLng()),
          a = i.project(e.getLatLng());
      return i.unproject(o._add(a)._divideBy(2));
    }
  }), L.Polyline.addInitHook(function () {
    (this || _global).editing || (L.Edit.Poly && ((this || _global).editing = new L.Edit.Poly(this || _global), (this || _global).options.editable && (this || _global).editing.enable()), this.on("add", function () {
      (this || _global).editing && (this || _global).editing.enabled() && (this || _global).editing.addHooks();
    }), this.on("remove", function () {
      (this || _global).editing && (this || _global).editing.enabled() && (this || _global).editing.removeHooks();
    }));
  }), L.Edit = L.Edit || {}, L.Edit.SimpleShape = L.Handler.extend({
    options: {
      moveIcon: new L.DivIcon({
        iconSize: new L.Point(8, 8),
        className: "leaflet-div-icon leaflet-editing-icon leaflet-edit-move"
      }),
      resizeIcon: new L.DivIcon({
        iconSize: new L.Point(8, 8),
        className: "leaflet-div-icon leaflet-editing-icon leaflet-edit-resize"
      }),
      touchMoveIcon: new L.DivIcon({
        iconSize: new L.Point(20, 20),
        className: "leaflet-div-icon leaflet-editing-icon leaflet-edit-move leaflet-touch-icon"
      }),
      touchResizeIcon: new L.DivIcon({
        iconSize: new L.Point(20, 20),
        className: "leaflet-div-icon leaflet-editing-icon leaflet-edit-resize leaflet-touch-icon"
      })
    },
    initialize: function (t, e) {
      L.Browser.touch && ((this || _global).options.moveIcon = (this || _global).options.touchMoveIcon, (this || _global).options.resizeIcon = (this || _global).options.touchResizeIcon), (this || _global)._shape = t, L.Util.setOptions(this || _global, e);
    },
    addHooks: function () {
      var t = (this || _global)._shape;
      (this || _global)._shape._map && ((this || _global)._map = (this || _global)._shape._map, t.setStyle(t.options.editing), t._map && ((this || _global)._map = t._map, (this || _global)._markerGroup || this._initMarkers(), (this || _global)._map.addLayer((this || _global)._markerGroup)));
    },
    removeHooks: function () {
      var t = (this || _global)._shape;

      if (t.setStyle(t.options.original), t._map) {
        this._unbindMarker((this || _global)._moveMarker);

        for (var e = 0, i = (this || _global)._resizeMarkers.length; e < i; e++) this._unbindMarker((this || _global)._resizeMarkers[e]);

        (this || _global)._resizeMarkers = null, (this || _global)._map.removeLayer((this || _global)._markerGroup), delete (this || _global)._markerGroup;
      }

      (this || _global)._map = null;
    },
    updateMarkers: function () {
      (this || _global)._markerGroup.clearLayers(), this._initMarkers();
    },
    _initMarkers: function () {
      (this || _global)._markerGroup || ((this || _global)._markerGroup = new L.LayerGroup()), this._createMoveMarker(), this._createResizeMarker();
    },
    _createMoveMarker: function () {},
    _createResizeMarker: function () {},
    _createMarker: function (t, e) {
      var i = new L.Marker.Touch(t, {
        draggable: !0,
        icon: e,
        zIndexOffset: 10
      });
      return this._bindMarker(i), (this || _global)._markerGroup.addLayer(i), i;
    },
    _bindMarker: function (t) {
      t.on("dragstart", (this || _global)._onMarkerDragStart, this || _global).on("drag", (this || _global)._onMarkerDrag, this || _global).on("dragend", (this || _global)._onMarkerDragEnd, this || _global).on("touchstart", (this || _global)._onTouchStart, this || _global).on("touchmove", (this || _global)._onTouchMove, this || _global).on("MSPointerMove", (this || _global)._onTouchMove, this || _global).on("touchend", (this || _global)._onTouchEnd, this || _global).on("MSPointerUp", (this || _global)._onTouchEnd, this || _global);
    },
    _unbindMarker: function (t) {
      t.off("dragstart", (this || _global)._onMarkerDragStart, this || _global).off("drag", (this || _global)._onMarkerDrag, this || _global).off("dragend", (this || _global)._onMarkerDragEnd, this || _global).off("touchstart", (this || _global)._onTouchStart, this || _global).off("touchmove", (this || _global)._onTouchMove, this || _global).off("MSPointerMove", (this || _global)._onTouchMove, this || _global).off("touchend", (this || _global)._onTouchEnd, this || _global).off("MSPointerUp", (this || _global)._onTouchEnd, this || _global);
    },
    _onMarkerDragStart: function (t) {
      t.target.setOpacity(0), (this || _global)._shape.fire("editstart");
    },
    _fireEdit: function () {
      (this || _global)._shape.edited = !0, (this || _global)._shape.fire("edit");
    },
    _onMarkerDrag: function (t) {
      var e = t.target,
          i = e.getLatLng();
      e === (this || _global)._moveMarker ? this._move(i) : this._resize(i), (this || _global)._shape.redraw(), (this || _global)._shape.fire("editdrag");
    },
    _onMarkerDragEnd: function (t) {
      t.target.setOpacity(1), this._fireEdit();
    },
    _onTouchStart: function (t) {
      if (L.Edit.SimpleShape.prototype._onMarkerDragStart.call(this || _global, t), "function" == typeof (this || _global)._getCorners) {
        var e = this._getCorners(),
            i = t.target,
            o = i._cornerIndex;

        i.setOpacity(0), (this || _global)._oppositeCorner = e[(o + 2) % 4], this._toggleCornerMarkers(0, o);
      }

      (this || _global)._shape.fire("editstart");
    },
    _onTouchMove: function (t) {
      var e = (this || _global)._map.mouseEventToLayerPoint(t.originalEvent.touches[0]),
          i = (this || _global)._map.layerPointToLatLng(e);

      return t.target === (this || _global)._moveMarker ? this._move(i) : this._resize(i), (this || _global)._shape.redraw(), !1;
    },
    _onTouchEnd: function (t) {
      t.target.setOpacity(1), this.updateMarkers(), this._fireEdit();
    },
    _move: function () {},
    _resize: function () {}
  }), L.Edit = L.Edit || {}, L.Edit.Rectangle = L.Edit.SimpleShape.extend({
    _createMoveMarker: function () {
      var t = (this || _global)._shape.getBounds(),
          e = t.getCenter();

      (this || _global)._moveMarker = this._createMarker(e, (this || _global).options.moveIcon);
    },
    _createResizeMarker: function () {
      var t = this._getCorners();

      (this || _global)._resizeMarkers = [];

      for (var e = 0, i = t.length; e < i; e++) (this || _global)._resizeMarkers.push(this._createMarker(t[e], (this || _global).options.resizeIcon)), (this || _global)._resizeMarkers[e]._cornerIndex = e;
    },
    _onMarkerDragStart: function (t) {
      L.Edit.SimpleShape.prototype._onMarkerDragStart.call(this || _global, t);

      var e = this._getCorners(),
          i = t.target,
          o = i._cornerIndex;

      (this || _global)._oppositeCorner = e[(o + 2) % 4], this._toggleCornerMarkers(0, o);
    },
    _onMarkerDragEnd: function (t) {
      var e,
          i,
          o = t.target;
      o === (this || _global)._moveMarker && (e = (this || _global)._shape.getBounds(), i = e.getCenter(), o.setLatLng(i)), this._toggleCornerMarkers(1), this._repositionCornerMarkers(), L.Edit.SimpleShape.prototype._onMarkerDragEnd.call(this || _global, t);
    },
    _move: function (t) {
      for (var e, i = (this || _global)._shape._defaultShape ? (this || _global)._shape._defaultShape() : (this || _global)._shape.getLatLngs(), o = (this || _global)._shape.getBounds(), a = o.getCenter(), n = [], s = 0, r = i.length; s < r; s++) e = [i[s].lat - a.lat, i[s].lng - a.lng], n.push([t.lat + e[0], t.lng + e[1]]);

      (this || _global)._shape.setLatLngs(n), this._repositionCornerMarkers(), (this || _global)._map.fire(L.Draw.Event.EDITMOVE, {
        layer: (this || _global)._shape
      });
    },
    _resize: function (t) {
      var e;
      (this || _global)._shape.setBounds(L.latLngBounds(t, (this || _global)._oppositeCorner)), e = (this || _global)._shape.getBounds(), (this || _global)._moveMarker.setLatLng(e.getCenter()), (this || _global)._map.fire(L.Draw.Event.EDITRESIZE, {
        layer: (this || _global)._shape
      });
    },
    _getCorners: function () {
      var t = (this || _global)._shape.getBounds();

      return [t.getNorthWest(), t.getNorthEast(), t.getSouthEast(), t.getSouthWest()];
    },
    _toggleCornerMarkers: function (t) {
      for (var e = 0, i = (this || _global)._resizeMarkers.length; e < i; e++) (this || _global)._resizeMarkers[e].setOpacity(t);
    },
    _repositionCornerMarkers: function () {
      for (var t = this._getCorners(), e = 0, i = (this || _global)._resizeMarkers.length; e < i; e++) (this || _global)._resizeMarkers[e].setLatLng(t[e]);
    }
  }), L.Rectangle.addInitHook(function () {
    L.Edit.Rectangle && ((this || _global).editing = new L.Edit.Rectangle(this || _global), (this || _global).options.editable && (this || _global).editing.enable());
  }), L.Edit = L.Edit || {}, L.Edit.CircleMarker = L.Edit.SimpleShape.extend({
    _createMoveMarker: function () {
      var t = (this || _global)._shape.getLatLng();

      (this || _global)._moveMarker = this._createMarker(t, (this || _global).options.moveIcon);
    },
    _createResizeMarker: function () {
      (this || _global)._resizeMarkers = [];
    },
    _move: function (t) {
      if ((this || _global)._resizeMarkers.length) {
        var e = this._getResizeMarkerPoint(t);

        (this || _global)._resizeMarkers[0].setLatLng(e);
      }

      (this || _global)._shape.setLatLng(t), (this || _global)._map.fire(L.Draw.Event.EDITMOVE, {
        layer: (this || _global)._shape
      });
    }
  }), L.CircleMarker.addInitHook(function () {
    L.Edit.CircleMarker && ((this || _global).editing = new L.Edit.CircleMarker(this || _global), (this || _global).options.editable && (this || _global).editing.enable()), this.on("add", function () {
      (this || _global).editing && (this || _global).editing.enabled() && (this || _global).editing.addHooks();
    }), this.on("remove", function () {
      (this || _global).editing && (this || _global).editing.enabled() && (this || _global).editing.removeHooks();
    });
  }), L.Edit = L.Edit || {}, L.Edit.Circle = L.Edit.CircleMarker.extend({
    _createResizeMarker: function () {
      var t = (this || _global)._shape.getLatLng(),
          e = this._getResizeMarkerPoint(t);

      (this || _global)._resizeMarkers = [], (this || _global)._resizeMarkers.push(this._createMarker(e, (this || _global).options.resizeIcon));
    },
    _getResizeMarkerPoint: function (t) {
      var e = (this || _global)._shape._radius * Math.cos(Math.PI / 4),
          i = (this || _global)._map.project(t);

      return (this || _global)._map.unproject([i.x + e, i.y - e]);
    },
    _resize: function (t) {
      var e = (this || _global)._moveMarker.getLatLng();

      L.GeometryUtil.isVersion07x() ? _global.radius = radius = e.distanceTo(t) : radius = (this || _global)._map.distance(e, t), (this || _global)._shape.setRadius(radius), (this || _global)._map.editTooltip && (this || _global)._map._editTooltip.updateContent({
        text: L.drawLocal.edit.handlers.edit.tooltip.subtext + "<br />" + L.drawLocal.edit.handlers.edit.tooltip.text,
        subtext: L.drawLocal.draw.handlers.circle.radius + ": " + L.GeometryUtil.readableDistance(radius, !0, (this || _global).options.feet, (this || _global).options.nautic)
      }), (this || _global)._shape.setRadius(radius), (this || _global)._map.fire(L.Draw.Event.EDITRESIZE, {
        layer: (this || _global)._shape
      });
    }
  }), L.Circle.addInitHook(function () {
    L.Edit.Circle && ((this || _global).editing = new L.Edit.Circle(this || _global), (this || _global).options.editable && (this || _global).editing.enable());
  }), L.Map.mergeOptions({
    touchExtend: !0
  }), L.Map.TouchExtend = L.Handler.extend({
    initialize: function (t) {
      (this || _global)._map = t, (this || _global)._container = t._container, (this || _global)._pane = t._panes.overlayPane;
    },
    addHooks: function () {
      L.DomEvent.on((this || _global)._container, "touchstart", (this || _global)._onTouchStart, this || _global), L.DomEvent.on((this || _global)._container, "touchend", (this || _global)._onTouchEnd, this || _global), L.DomEvent.on((this || _global)._container, "touchmove", (this || _global)._onTouchMove, this || _global), this._detectIE() ? (L.DomEvent.on((this || _global)._container, "MSPointerDown", (this || _global)._onTouchStart, this || _global), L.DomEvent.on((this || _global)._container, "MSPointerUp", (this || _global)._onTouchEnd, this || _global), L.DomEvent.on((this || _global)._container, "MSPointerMove", (this || _global)._onTouchMove, this || _global), L.DomEvent.on((this || _global)._container, "MSPointerCancel", (this || _global)._onTouchCancel, this || _global)) : (L.DomEvent.on((this || _global)._container, "touchcancel", (this || _global)._onTouchCancel, this || _global), L.DomEvent.on((this || _global)._container, "touchleave", (this || _global)._onTouchLeave, this || _global));
    },
    removeHooks: function () {
      L.DomEvent.off((this || _global)._container, "touchstart", (this || _global)._onTouchStart, this || _global), L.DomEvent.off((this || _global)._container, "touchend", (this || _global)._onTouchEnd, this || _global), L.DomEvent.off((this || _global)._container, "touchmove", (this || _global)._onTouchMove, this || _global), this._detectIE() ? (L.DomEvent.off((this || _global)._container, "MSPointerDown", (this || _global)._onTouchStart, this || _global), L.DomEvent.off((this || _global)._container, "MSPointerUp", (this || _global)._onTouchEnd, this || _global), L.DomEvent.off((this || _global)._container, "MSPointerMove", (this || _global)._onTouchMove, this || _global), L.DomEvent.off((this || _global)._container, "MSPointerCancel", (this || _global)._onTouchCancel, this || _global)) : (L.DomEvent.off((this || _global)._container, "touchcancel", (this || _global)._onTouchCancel, this || _global), L.DomEvent.off((this || _global)._container, "touchleave", (this || _global)._onTouchLeave, this || _global));
    },
    _touchEvent: function (t, e) {
      var i = {};

      if (void 0 !== t.touches) {
        if (!t.touches.length) return;
        i = t.touches[0];
      } else {
        if ("touch" !== t.pointerType) return;
        if (i = t, !this._filterClick(t)) return;
      }

      var o = (this || _global)._map.mouseEventToContainerPoint(i),
          a = (this || _global)._map.mouseEventToLayerPoint(i),
          n = (this || _global)._map.layerPointToLatLng(a);

      (this || _global)._map.fire(e, {
        latlng: n,
        layerPoint: a,
        containerPoint: o,
        pageX: i.pageX,
        pageY: i.pageY,
        originalEvent: t
      });
    },
    _filterClick: function (t) {
      var e = t.timeStamp || t.originalEvent.timeStamp,
          i = L.DomEvent._lastClick && e - L.DomEvent._lastClick;
      return i && i > 100 && i < 500 || t.target._simulatedClick && !t._simulated ? (L.DomEvent.stop(t), !1) : (L.DomEvent._lastClick = e, !0);
    },
    _onTouchStart: function (t) {
      if ((this || _global)._map._loaded) {
        this._touchEvent(t, "touchstart");
      }
    },
    _onTouchEnd: function (t) {
      if ((this || _global)._map._loaded) {
        this._touchEvent(t, "touchend");
      }
    },
    _onTouchCancel: function (t) {
      if ((this || _global)._map._loaded) {
        var e = "touchcancel";
        this._detectIE() && (e = "pointercancel"), this._touchEvent(t, e);
      }
    },
    _onTouchLeave: function (t) {
      if ((this || _global)._map._loaded) {
        this._touchEvent(t, "touchleave");
      }
    },
    _onTouchMove: function (t) {
      if ((this || _global)._map._loaded) {
        this._touchEvent(t, "touchmove");
      }
    },
    _detectIE: function () {
      var e = t.navigator.userAgent,
          i = e.indexOf("MSIE ");
      if (i > 0) return parseInt(e.substring(i + 5, e.indexOf(".", i)), 10);

      if (e.indexOf("Trident/") > 0) {
        var o = e.indexOf("rv:");
        return parseInt(e.substring(o + 3, e.indexOf(".", o)), 10);
      }

      var a = e.indexOf("Edge/");
      return a > 0 && parseInt(e.substring(a + 5, e.indexOf(".", a)), 10);
    }
  }), L.Map.addInitHook("addHandler", "touchExtend", L.Map.TouchExtend), L.Marker.Touch = L.Marker.extend({
    _initInteraction: function () {
      return (this || _global).addInteractiveTarget ? L.Marker.prototype._initInteraction.apply(this || _global) : this._initInteractionLegacy();
    },
    _initInteractionLegacy: function () {
      if ((this || _global).options.clickable) {
        var t = (this || _global)._icon,
            e = ["dblclick", "mousedown", "mouseover", "mouseout", "contextmenu", "touchstart", "touchend", "touchmove"];
        (this || _global)._detectIE ? e.concat(["MSPointerDown", "MSPointerUp", "MSPointerMove", "MSPointerCancel"]) : e.concat(["touchcancel"]), L.DomUtil.addClass(t, "leaflet-clickable"), L.DomEvent.on(t, "click", (this || _global)._onMouseClick, this || _global), L.DomEvent.on(t, "keypress", (this || _global)._onKeyPress, this || _global);

        for (var i = 0; i < e.length; i++) L.DomEvent.on(t, e[i], (this || _global)._fireMouseEvent, this || _global);

        L.Handler.MarkerDrag && ((this || _global).dragging = new L.Handler.MarkerDrag(this || _global), (this || _global).options.draggable && (this || _global).dragging.enable());
      }
    },
    _detectIE: function () {
      var e = t.navigator.userAgent,
          i = e.indexOf("MSIE ");
      if (i > 0) return parseInt(e.substring(i + 5, e.indexOf(".", i)), 10);

      if (e.indexOf("Trident/") > 0) {
        var o = e.indexOf("rv:");
        return parseInt(e.substring(o + 3, e.indexOf(".", o)), 10);
      }

      var a = e.indexOf("Edge/");
      return a > 0 && parseInt(e.substring(a + 5, e.indexOf(".", a)), 10);
    }
  }), L.LatLngUtil = {
    cloneLatLngs: function (t) {
      for (var e = [], i = 0, o = t.length; i < o; i++) Array.isArray(t[i]) ? e.push(L.LatLngUtil.cloneLatLngs(t[i])) : e.push(this.cloneLatLng(t[i]));

      return e;
    },
    cloneLatLng: function (t) {
      return L.latLng(t.lat, t.lng);
    }
  }, function () {
    var t = {
      km: 2,
      ha: 2,
      m: 0,
      mi: 2,
      ac: 2,
      yd: 0,
      ft: 0,
      nm: 2
    };
    L.GeometryUtil = L.extend(L.GeometryUtil || {}, {
      geodesicArea: function (t) {
        var e,
            i,
            o = t.length,
            a = 0,
            n = Math.PI / 180;

        if (o > 2) {
          for (var s = 0; s < o; s++) e = t[s], i = t[(s + 1) % o], a += (i.lng - e.lng) * n * (2 + Math.sin(e.lat * n) + Math.sin(i.lat * n));

          a = 6378137 * a * 6378137 / 2;
        }

        return Math.abs(a);
      },
      formattedNumber: function (t, e) {
        var i = parseFloat(t).toFixed(e),
            o = L.drawLocal.format && L.drawLocal.format.numeric,
            a = o && o.delimiters,
            n = a && a.thousands,
            s = a && a.decimal;

        if (n || s) {
          var r = i.split(".");
          i = n ? r[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1" + n) : r[0], s = s || ".", r.length > 1 && (i = i + s + r[1]);
        }

        return i;
      },
      readableArea: function (e, i, o) {
        var a,
            n,
            o = L.Util.extend({}, t, o);
        return i ? (n = ["ha", "m"], _global.type = type = typeof i, "string" === type ? n = [i] : "boolean" !== type && (n = i), a = e >= 1000000 && -1 !== n.indexOf("km") ? L.GeometryUtil.formattedNumber(0.000001 * e, o.km) + " km\xB2" : e >= 10000 && -1 !== n.indexOf("ha") ? L.GeometryUtil.formattedNumber(0.0001 * e, o.ha) + " ha" : L.GeometryUtil.formattedNumber(e, o.m) + " m\xB2") : (e /= 0.836127, a = e >= 3097600 ? L.GeometryUtil.formattedNumber(e / 3097600, o.mi) + " mi\xB2" : e >= 4840 ? L.GeometryUtil.formattedNumber(e / 4840, o.ac) + " acres" : L.GeometryUtil.formattedNumber(e, o.yd) + " yd\xB2"), a;
      },
      readableDistance: function (e, i, o, a, n) {
        var s,
            n = L.Util.extend({}, t, n);

        switch (i ? "string" == typeof i ? i : "metric" : o ? "feet" : a ? "nauticalMile" : "yards") {
          case "metric":
            s = e > 1000 ? L.GeometryUtil.formattedNumber(e / 1000, n.km) + " km" : L.GeometryUtil.formattedNumber(e, n.m) + " m";
            break;

          case "feet":
            e *= 3.28083, s = L.GeometryUtil.formattedNumber(e, n.ft) + " ft";
            break;

          case "nauticalMile":
            e *= 0.53996, s = L.GeometryUtil.formattedNumber(e / 1000, n.nm) + " nm";
            break;

          case "yards":
          default:
            e *= 1.09361, s = e > 1760 ? L.GeometryUtil.formattedNumber(e / 1760, n.mi) + " miles" : L.GeometryUtil.formattedNumber(e, n.yd) + " yd";
        }

        return s;
      },
      isVersion07x: function () {
        var t = L.version.split(".");
        return 0 === parseInt(t[0], 10) && 7 === parseInt(t[1], 10);
      }
    });
  }(), L.Util.extend(L.LineUtil, {
    segmentsIntersect: function (t, e, i, o) {
      return this._checkCounterclockwise(t, i, o) !== this._checkCounterclockwise(e, i, o) && this._checkCounterclockwise(t, e, i) !== this._checkCounterclockwise(t, e, o);
    },
    _checkCounterclockwise: function (t, e, i) {
      return (i.y - t.y) * (e.x - t.x) > (e.y - t.y) * (i.x - t.x);
    }
  }), L.Polyline.include({
    intersects: function () {
      var t,
          e,
          i,
          o = this._getProjectedPoints(),
          a = o ? o.length : 0;

      if (this._tooFewPointsForIntersection()) return !1;

      for (t = a - 1; t >= 3; t--) if (e = o[t - 1], i = o[t], this._lineSegmentsIntersectsRange(e, i, t - 2)) return !0;

      return !1;
    },
    newLatLngIntersects: function (t, e) {
      return !!(this || _global)._map && this.newPointIntersects((this || _global)._map.latLngToLayerPoint(t), e);
    },
    newPointIntersects: function (t, e) {
      var i = this._getProjectedPoints(),
          o = i ? i.length : 0,
          a = i ? i[o - 1] : null,
          n = o - 2;

      return !this._tooFewPointsForIntersection(1) && this._lineSegmentsIntersectsRange(a, t, n, e ? 1 : 0);
    },
    _tooFewPointsForIntersection: function (t) {
      var e = this._getProjectedPoints(),
          i = e ? e.length : 0;

      return i += t || 0, !e || i <= 3;
    },
    _lineSegmentsIntersectsRange: function (t, e, i, o) {
      var a,
          n,
          s = this._getProjectedPoints();

      o = o || 0;

      for (var r = i; r > o; r--) if (a = s[r - 1], n = s[r], L.LineUtil.segmentsIntersect(t, e, a, n)) return !0;

      return !1;
    },
    _getProjectedPoints: function () {
      if (!(this || _global)._defaultShape) return (this || _global)._originalPoints;

      for (var t = [], e = this._defaultShape(), i = 0; i < e.length; i++) t.push((this || _global)._map.latLngToLayerPoint(e[i]));

      return t;
    }
  }), L.Polygon.include({
    intersects: function () {
      var t,
          e,
          i,
          o,
          a = this._getProjectedPoints();

      return !this._tooFewPointsForIntersection() && (!!L.Polyline.prototype.intersects.call(this || _global) || (t = a.length, e = a[0], i = a[t - 1], o = t - 2, this._lineSegmentsIntersectsRange(i, e, o, 1)));
    }
  }), L.Control.Draw = L.Control.extend({
    options: {
      position: "topleft",
      draw: {},
      edit: !1
    },
    initialize: function (t) {
      if (L.version < "0.7") throw new Error("Leaflet.draw 0.2.3+ requires Leaflet 0.7.0+. Download latest from https://github.com/Leaflet/Leaflet/");
      L.Control.prototype.initialize.call(this || _global, t);
      var e;
      (this || _global)._toolbars = {}, L.DrawToolbar && (this || _global).options.draw && (e = new L.DrawToolbar((this || _global).options.draw), (this || _global)._toolbars[L.DrawToolbar.TYPE] = e, (this || _global)._toolbars[L.DrawToolbar.TYPE].on("enable", (this || _global)._toolbarEnabled, this || _global)), L.EditToolbar && (this || _global).options.edit && (e = new L.EditToolbar((this || _global).options.edit), (this || _global)._toolbars[L.EditToolbar.TYPE] = e, (this || _global)._toolbars[L.EditToolbar.TYPE].on("enable", (this || _global)._toolbarEnabled, this || _global)), L.toolbar = this || _global;
    },
    onAdd: function (t) {
      var e,
          i = L.DomUtil.create("div", "leaflet-draw"),
          o = !1;

      for (var a in (this || _global)._toolbars) (this || _global)._toolbars.hasOwnProperty(a) && (e = (this || _global)._toolbars[a].addToolbar(t)) && (o || (L.DomUtil.hasClass(e, "leaflet-draw-toolbar-top") || L.DomUtil.addClass(e.childNodes[0], "leaflet-draw-toolbar-top"), o = !0), i.appendChild(e));

      return i;
    },
    onRemove: function () {
      for (var t in (this || _global)._toolbars) (this || _global)._toolbars.hasOwnProperty(t) && (this || _global)._toolbars[t].removeToolbar();
    },
    setDrawingOptions: function (t) {
      for (var e in (this || _global)._toolbars) (this || _global)._toolbars[e] instanceof L.DrawToolbar && (this || _global)._toolbars[e].setOptions(t);
    },
    _toolbarEnabled: function (t) {
      var e = t.target;

      for (var i in (this || _global)._toolbars) (this || _global)._toolbars[i] !== e && (this || _global)._toolbars[i].disable();
    }
  }), L.Map.mergeOptions({
    drawControlTooltips: !0,
    drawControl: !1
  }), L.Map.addInitHook(function () {
    (this || _global).options.drawControl && ((this || _global).drawControl = new L.Control.Draw(), this.addControl((this || _global).drawControl));
  }), L.Toolbar = L.Class.extend({
    initialize: function (t) {
      L.setOptions(this || _global, t), (this || _global)._modes = {}, (this || _global)._actionButtons = [], (this || _global)._activeMode = null;
      var e = L.version.split(".");
      1 === parseInt(e[0], 10) && parseInt(e[1], 10) >= 2 ? L.Toolbar.include(L.Evented.prototype) : L.Toolbar.include(L.Mixin.Events);
    },
    enabled: function () {
      return null !== (this || _global)._activeMode;
    },
    disable: function () {
      this.enabled() && (this || _global)._activeMode.handler.disable();
    },
    addToolbar: function (t) {
      var e,
          i = L.DomUtil.create("div", "leaflet-draw-section"),
          o = 0,
          a = (this || _global)._toolbarClass || "",
          n = this.getModeHandlers(t);

      for ((this || _global)._toolbarContainer = L.DomUtil.create("div", "leaflet-draw-toolbar leaflet-bar"), (this || _global)._map = t, e = 0; e < n.length; e++) n[e].enabled && this._initModeHandler(n[e].handler, (this || _global)._toolbarContainer, o++, a, n[e].title);

      if (o) return (this || _global)._lastButtonIndex = --o, (this || _global)._actionsContainer = L.DomUtil.create("ul", "leaflet-draw-actions"), i.appendChild((this || _global)._toolbarContainer), i.appendChild((this || _global)._actionsContainer), i;
    },
    removeToolbar: function () {
      for (var t in (this || _global)._modes) (this || _global)._modes.hasOwnProperty(t) && (this._disposeButton((this || _global)._modes[t].button, (this || _global)._modes[t].handler.enable, (this || _global)._modes[t].handler), (this || _global)._modes[t].handler.disable(), (this || _global)._modes[t].handler.off("enabled", (this || _global)._handlerActivated, this || _global).off("disabled", (this || _global)._handlerDeactivated, this || _global));

      (this || _global)._modes = {};

      for (var e = 0, i = (this || _global)._actionButtons.length; e < i; e++) this._disposeButton((this || _global)._actionButtons[e].button, (this || _global)._actionButtons[e].callback, this || _global);

      (this || _global)._actionButtons = [], (this || _global)._actionsContainer = null;
    },
    _initModeHandler: function (t, e, i, o, a) {
      var n = t.type;
      (this || _global)._modes[n] = {}, (this || _global)._modes[n].handler = t, (this || _global)._modes[n].button = this._createButton({
        type: n,
        title: a,
        className: o + "-" + n,
        container: e,
        callback: (this || _global)._modes[n].handler.enable,
        context: (this || _global)._modes[n].handler
      }), (this || _global)._modes[n].buttonIndex = i, (this || _global)._modes[n].handler.on("enabled", (this || _global)._handlerActivated, this || _global).on("disabled", (this || _global)._handlerDeactivated, this || _global);
    },
    _detectIOS: function () {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !t.MSStream;
    },
    _createButton: function (t) {
      var e = L.DomUtil.create("a", t.className || "", t.container),
          i = L.DomUtil.create("span", "sr-only", t.container);
      e.href = "#", e.appendChild(i), t.title && (e.title = t.title, i.innerHTML = t.title), t.text && (e.innerHTML = t.text, i.innerHTML = t.text);
      var o = this._detectIOS() ? "touchstart" : "click";
      return L.DomEvent.on(e, "click", L.DomEvent.stopPropagation).on(e, "mousedown", L.DomEvent.stopPropagation).on(e, "dblclick", L.DomEvent.stopPropagation).on(e, "touchstart", L.DomEvent.stopPropagation).on(e, "click", L.DomEvent.preventDefault).on(e, o, t.callback, t.context), e;
    },
    _disposeButton: function (t, e) {
      var i = this._detectIOS() ? "touchstart" : "click";
      L.DomEvent.off(t, "click", L.DomEvent.stopPropagation).off(t, "mousedown", L.DomEvent.stopPropagation).off(t, "dblclick", L.DomEvent.stopPropagation).off(t, "touchstart", L.DomEvent.stopPropagation).off(t, "click", L.DomEvent.preventDefault).off(t, i, e);
    },
    _handlerActivated: function (t) {
      this.disable(), (this || _global)._activeMode = (this || _global)._modes[t.handler], L.DomUtil.addClass((this || _global)._activeMode.button, "leaflet-draw-toolbar-button-enabled"), this._showActionsToolbar(), this.fire("enable");
    },
    _handlerDeactivated: function () {
      this._hideActionsToolbar(), L.DomUtil.removeClass((this || _global)._activeMode.button, "leaflet-draw-toolbar-button-enabled"), (this || _global)._activeMode = null, this.fire("disable");
    },
    _createActions: function (t) {
      var e,
          i,
          o,
          a,
          n = (this || _global)._actionsContainer,
          s = this.getActions(t),
          r = s.length;

      for (i = 0, o = (this || _global)._actionButtons.length; i < o; i++) this._disposeButton((this || _global)._actionButtons[i].button, (this || _global)._actionButtons[i].callback);

      for ((this || _global)._actionButtons = []; n.firstChild;) n.removeChild(n.firstChild);

      for (var l = 0; l < r; l++) "enabled" in s[l] && !s[l].enabled || (e = L.DomUtil.create("li", "", n), a = this._createButton({
        title: s[l].title,
        text: s[l].text,
        container: e,
        callback: s[l].callback,
        context: s[l].context
      }), (this || _global)._actionButtons.push({
        button: a,
        callback: s[l].callback
      }));
    },
    _showActionsToolbar: function () {
      var t = (this || _global)._activeMode.buttonIndex,
          e = (this || _global)._lastButtonIndex,
          i = (this || _global)._activeMode.button.offsetTop - 1;
      this._createActions((this || _global)._activeMode.handler), (this || _global)._actionsContainer.style.top = i + "px", 0 === t && (L.DomUtil.addClass((this || _global)._toolbarContainer, "leaflet-draw-toolbar-notop"), L.DomUtil.addClass((this || _global)._actionsContainer, "leaflet-draw-actions-top")), t === e && (L.DomUtil.addClass((this || _global)._toolbarContainer, "leaflet-draw-toolbar-nobottom"), L.DomUtil.addClass((this || _global)._actionsContainer, "leaflet-draw-actions-bottom")), (this || _global)._actionsContainer.style.display = "block", (this || _global)._map.fire(L.Draw.Event.TOOLBAROPENED);
    },
    _hideActionsToolbar: function () {
      (this || _global)._actionsContainer.style.display = "none", L.DomUtil.removeClass((this || _global)._toolbarContainer, "leaflet-draw-toolbar-notop"), L.DomUtil.removeClass((this || _global)._toolbarContainer, "leaflet-draw-toolbar-nobottom"), L.DomUtil.removeClass((this || _global)._actionsContainer, "leaflet-draw-actions-top"), L.DomUtil.removeClass((this || _global)._actionsContainer, "leaflet-draw-actions-bottom"), (this || _global)._map.fire(L.Draw.Event.TOOLBARCLOSED);
    }
  }), L.Draw = L.Draw || {}, L.Draw.Tooltip = L.Class.extend({
    initialize: function (t) {
      (this || _global)._map = t, (this || _global)._popupPane = t._panes.popupPane, (this || _global)._visible = !1, (this || _global)._container = t.options.drawControlTooltips ? L.DomUtil.create("div", "leaflet-draw-tooltip", (this || _global)._popupPane) : null, (this || _global)._singleLineLabel = !1, (this || _global)._map.on("mouseout", (this || _global)._onMouseOut, this || _global);
    },
    dispose: function () {
      (this || _global)._map.off("mouseout", (this || _global)._onMouseOut, this || _global), (this || _global)._container && ((this || _global)._popupPane.removeChild((this || _global)._container), (this || _global)._container = null);
    },
    updateContent: function (t) {
      return (this || _global)._container ? (t.subtext = t.subtext || "", 0 !== t.subtext.length || (this || _global)._singleLineLabel ? t.subtext.length > 0 && (this || _global)._singleLineLabel && (L.DomUtil.removeClass((this || _global)._container, "leaflet-draw-tooltip-single"), (this || _global)._singleLineLabel = !1) : (L.DomUtil.addClass((this || _global)._container, "leaflet-draw-tooltip-single"), (this || _global)._singleLineLabel = !0), (this || _global)._container.innerHTML = (t.subtext.length > 0 ? "<span class=\"leaflet-draw-tooltip-subtext\">" + t.subtext + "</span><br />" : "") + "<span>" + t.text + "</span>", t.text || t.subtext ? ((this || _global)._visible = !0, (this || _global)._container.style.visibility = "inherit") : ((this || _global)._visible = !1, (this || _global)._container.style.visibility = "hidden"), this || _global) : this || _global;
    },
    updatePosition: function (t) {
      var e = (this || _global)._map.latLngToLayerPoint(t),
          i = (this || _global)._container;

      return (this || _global)._container && ((this || _global)._visible && (i.style.visibility = "inherit"), L.DomUtil.setPosition(i, e)), this || _global;
    },
    showAsError: function () {
      return (this || _global)._container && L.DomUtil.addClass((this || _global)._container, "leaflet-error-draw-tooltip"), this || _global;
    },
    removeError: function () {
      return (this || _global)._container && L.DomUtil.removeClass((this || _global)._container, "leaflet-error-draw-tooltip"), this || _global;
    },
    _onMouseOut: function () {
      (this || _global)._container && ((this || _global)._container.style.visibility = "hidden");
    }
  }), L.DrawToolbar = L.Toolbar.extend({
    statics: {
      TYPE: "draw"
    },
    options: {
      polyline: {},
      polygon: {},
      rectangle: {},
      circle: {},
      marker: {},
      circlemarker: {}
    },
    initialize: function (t) {
      for (var e in (this || _global).options) (this || _global).options.hasOwnProperty(e) && t[e] && (t[e] = L.extend({}, (this || _global).options[e], t[e]));

      (this || _global)._toolbarClass = "leaflet-draw-draw", L.Toolbar.prototype.initialize.call(this || _global, t);
    },
    getModeHandlers: function (t) {
      return [{
        enabled: (this || _global).options.polyline,
        handler: new L.Draw.Polyline(t, (this || _global).options.polyline),
        title: L.drawLocal.draw.toolbar.buttons.polyline
      }, {
        enabled: (this || _global).options.polygon,
        handler: new L.Draw.Polygon(t, (this || _global).options.polygon),
        title: L.drawLocal.draw.toolbar.buttons.polygon
      }, {
        enabled: (this || _global).options.rectangle,
        handler: new L.Draw.Rectangle(t, (this || _global).options.rectangle),
        title: L.drawLocal.draw.toolbar.buttons.rectangle
      }, {
        enabled: (this || _global).options.circle,
        handler: new L.Draw.Circle(t, (this || _global).options.circle),
        title: L.drawLocal.draw.toolbar.buttons.circle
      }, {
        enabled: (this || _global).options.marker,
        handler: new L.Draw.Marker(t, (this || _global).options.marker),
        title: L.drawLocal.draw.toolbar.buttons.marker
      }, {
        enabled: (this || _global).options.circlemarker,
        handler: new L.Draw.CircleMarker(t, (this || _global).options.circlemarker),
        title: L.drawLocal.draw.toolbar.buttons.circlemarker
      }];
    },
    getActions: function (t) {
      return [{
        enabled: t.completeShape,
        title: L.drawLocal.draw.toolbar.finish.title,
        text: L.drawLocal.draw.toolbar.finish.text,
        callback: t.completeShape,
        context: t
      }, {
        enabled: t.deleteLastVertex,
        title: L.drawLocal.draw.toolbar.undo.title,
        text: L.drawLocal.draw.toolbar.undo.text,
        callback: t.deleteLastVertex,
        context: t
      }, {
        title: L.drawLocal.draw.toolbar.actions.title,
        text: L.drawLocal.draw.toolbar.actions.text,
        callback: (this || _global).disable,
        context: this || _global
      }];
    },
    setOptions: function (t) {
      L.setOptions(this || _global, t);

      for (var e in (this || _global)._modes) (this || _global)._modes.hasOwnProperty(e) && t.hasOwnProperty(e) && (this || _global)._modes[e].handler.setOptions(t[e]);
    }
  }), L.EditToolbar = L.Toolbar.extend({
    statics: {
      TYPE: "edit"
    },
    options: {
      edit: {
        selectedPathOptions: {
          dashArray: "10, 10",
          fill: !0,
          fillColor: "#fe57a1",
          fillOpacity: 0.1,
          maintainColor: !1
        }
      },
      remove: {},
      poly: null,
      featureGroup: null
    },
    initialize: function (t) {
      t.edit && (void 0 === t.edit.selectedPathOptions && (t.edit.selectedPathOptions = (this || _global).options.edit.selectedPathOptions), t.edit.selectedPathOptions = L.extend({}, (this || _global).options.edit.selectedPathOptions, t.edit.selectedPathOptions)), t.remove && (t.remove = L.extend({}, (this || _global).options.remove, t.remove)), t.poly && (t.poly = L.extend({}, (this || _global).options.poly, t.poly)), (this || _global)._toolbarClass = "leaflet-draw-edit", L.Toolbar.prototype.initialize.call(this || _global, t), (this || _global)._selectedFeatureCount = 0;
    },
    getModeHandlers: function (t) {
      var e = (this || _global).options.featureGroup;
      return [{
        enabled: (this || _global).options.edit,
        handler: new L.EditToolbar.Edit(t, {
          featureGroup: e,
          selectedPathOptions: (this || _global).options.edit.selectedPathOptions,
          poly: (this || _global).options.poly
        }),
        title: L.drawLocal.edit.toolbar.buttons.edit
      }, {
        enabled: (this || _global).options.remove,
        handler: new L.EditToolbar.Delete(t, {
          featureGroup: e
        }),
        title: L.drawLocal.edit.toolbar.buttons.remove
      }];
    },
    getActions: function (t) {
      var e = [{
        title: L.drawLocal.edit.toolbar.actions.save.title,
        text: L.drawLocal.edit.toolbar.actions.save.text,
        callback: (this || _global)._save,
        context: this || _global
      }, {
        title: L.drawLocal.edit.toolbar.actions.cancel.title,
        text: L.drawLocal.edit.toolbar.actions.cancel.text,
        callback: (this || _global).disable,
        context: this || _global
      }];
      return t.removeAllLayers && e.push({
        title: L.drawLocal.edit.toolbar.actions.clearAll.title,
        text: L.drawLocal.edit.toolbar.actions.clearAll.text,
        callback: (this || _global)._clearAllLayers,
        context: this || _global
      }), e;
    },
    addToolbar: function (t) {
      var e = L.Toolbar.prototype.addToolbar.call(this || _global, t);
      return this._checkDisabled(), (this || _global).options.featureGroup.on("layeradd layerremove", (this || _global)._checkDisabled, this || _global), e;
    },
    removeToolbar: function () {
      (this || _global).options.featureGroup.off("layeradd layerremove", (this || _global)._checkDisabled, this || _global), L.Toolbar.prototype.removeToolbar.call(this || _global);
    },
    disable: function () {
      this.enabled() && ((this || _global)._activeMode.handler.revertLayers(), L.Toolbar.prototype.disable.call(this || _global));
    },
    _save: function () {
      (this || _global)._activeMode.handler.save(), (this || _global)._activeMode && (this || _global)._activeMode.handler.disable();
    },
    _clearAllLayers: function () {
      (this || _global)._activeMode.handler.removeAllLayers(), (this || _global)._activeMode && (this || _global)._activeMode.handler.disable();
    },
    _checkDisabled: function () {
      var t,
          e = (this || _global).options.featureGroup,
          i = 0 !== e.getLayers().length;
      (this || _global).options.edit && (t = (this || _global)._modes[L.EditToolbar.Edit.TYPE].button, i ? L.DomUtil.removeClass(t, "leaflet-disabled") : L.DomUtil.addClass(t, "leaflet-disabled"), t.setAttribute("title", i ? L.drawLocal.edit.toolbar.buttons.edit : L.drawLocal.edit.toolbar.buttons.editDisabled)), (this || _global).options.remove && (t = (this || _global)._modes[L.EditToolbar.Delete.TYPE].button, i ? L.DomUtil.removeClass(t, "leaflet-disabled") : L.DomUtil.addClass(t, "leaflet-disabled"), t.setAttribute("title", i ? L.drawLocal.edit.toolbar.buttons.remove : L.drawLocal.edit.toolbar.buttons.removeDisabled));
    }
  }), L.EditToolbar.Edit = L.Handler.extend({
    statics: {
      TYPE: "edit"
    },
    initialize: function (t, e) {
      if (L.Handler.prototype.initialize.call(this || _global, t), L.setOptions(this || _global, e), (this || _global)._featureGroup = e.featureGroup, !((this || _global)._featureGroup instanceof L.FeatureGroup)) throw new Error("options.featureGroup must be a L.FeatureGroup");
      (this || _global)._uneditedLayerProps = {}, (this || _global).type = L.EditToolbar.Edit.TYPE;
      var i = L.version.split(".");
      1 === parseInt(i[0], 10) && parseInt(i[1], 10) >= 2 ? L.EditToolbar.Edit.include(L.Evented.prototype) : L.EditToolbar.Edit.include(L.Mixin.Events);
    },
    enable: function () {
      !(this || _global)._enabled && this._hasAvailableLayers() && (this.fire("enabled", {
        handler: (this || _global).type
      }), (this || _global)._map.fire(L.Draw.Event.EDITSTART, {
        handler: (this || _global).type
      }), L.Handler.prototype.enable.call(this || _global), (this || _global)._featureGroup.on("layeradd", (this || _global)._enableLayerEdit, this || _global).on("layerremove", (this || _global)._disableLayerEdit, this || _global));
    },
    disable: function () {
      (this || _global)._enabled && ((this || _global)._featureGroup.off("layeradd", (this || _global)._enableLayerEdit, this || _global).off("layerremove", (this || _global)._disableLayerEdit, this || _global), L.Handler.prototype.disable.call(this || _global), (this || _global)._map.fire(L.Draw.Event.EDITSTOP, {
        handler: (this || _global).type
      }), this.fire("disabled", {
        handler: (this || _global).type
      }));
    },
    addHooks: function () {
      var t = (this || _global)._map;
      t && (t.getContainer().focus(), (this || _global)._featureGroup.eachLayer((this || _global)._enableLayerEdit, this || _global), (this || _global)._tooltip = new L.Draw.Tooltip((this || _global)._map), (this || _global)._tooltip.updateContent({
        text: L.drawLocal.edit.handlers.edit.tooltip.text,
        subtext: L.drawLocal.edit.handlers.edit.tooltip.subtext
      }), t._editTooltip = (this || _global)._tooltip, this._updateTooltip(), (this || _global)._map.on("mousemove", (this || _global)._onMouseMove, this || _global).on("touchmove", (this || _global)._onMouseMove, this || _global).on("MSPointerMove", (this || _global)._onMouseMove, this || _global).on(L.Draw.Event.EDITVERTEX, (this || _global)._updateTooltip, this || _global));
    },
    removeHooks: function () {
      (this || _global)._map && ((this || _global)._featureGroup.eachLayer((this || _global)._disableLayerEdit, this || _global), (this || _global)._uneditedLayerProps = {}, (this || _global)._tooltip.dispose(), (this || _global)._tooltip = null, (this || _global)._map.off("mousemove", (this || _global)._onMouseMove, this || _global).off("touchmove", (this || _global)._onMouseMove, this || _global).off("MSPointerMove", (this || _global)._onMouseMove, this || _global).off(L.Draw.Event.EDITVERTEX, (this || _global)._updateTooltip, this || _global));
    },
    revertLayers: function () {
      (this || _global)._featureGroup.eachLayer(function (t) {
        this._revertLayer(t);
      }, this || _global);
    },
    save: function () {
      var t = new L.LayerGroup();
      (this || _global)._featureGroup.eachLayer(function (e) {
        e.edited && (t.addLayer(e), e.edited = !1);
      }), (this || _global)._map.fire(L.Draw.Event.EDITED, {
        layers: t
      });
    },
    _backupLayer: function (t) {
      var e = L.Util.stamp(t);
      (this || _global)._uneditedLayerProps[e] || (t instanceof L.Polyline || t instanceof L.Polygon || t instanceof L.Rectangle ? (this || _global)._uneditedLayerProps[e] = {
        latlngs: L.LatLngUtil.cloneLatLngs(t.getLatLngs())
      } : t instanceof L.Circle ? (this || _global)._uneditedLayerProps[e] = {
        latlng: L.LatLngUtil.cloneLatLng(t.getLatLng()),
        radius: t.getRadius()
      } : (t instanceof L.Marker || t instanceof L.CircleMarker) && ((this || _global)._uneditedLayerProps[e] = {
        latlng: L.LatLngUtil.cloneLatLng(t.getLatLng())
      }));
    },
    _getTooltipText: function () {
      return {
        text: L.drawLocal.edit.handlers.edit.tooltip.text,
        subtext: L.drawLocal.edit.handlers.edit.tooltip.subtext
      };
    },
    _updateTooltip: function () {
      (this || _global)._tooltip.updateContent(this._getTooltipText());
    },
    _revertLayer: function (t) {
      var e = L.Util.stamp(t);
      t.edited = !1, (this || _global)._uneditedLayerProps.hasOwnProperty(e) && (t instanceof L.Polyline || t instanceof L.Polygon || t instanceof L.Rectangle ? t.setLatLngs((this || _global)._uneditedLayerProps[e].latlngs) : t instanceof L.Circle ? (t.setLatLng((this || _global)._uneditedLayerProps[e].latlng), t.setRadius((this || _global)._uneditedLayerProps[e].radius)) : (t instanceof L.Marker || t instanceof L.CircleMarker) && t.setLatLng((this || _global)._uneditedLayerProps[e].latlng), t.fire("revert-edited", {
        layer: t
      }));
    },
    _enableLayerEdit: function (t) {
      var e,
          i,
          o = t.layer || t.target || t;
      this._backupLayer(o), (this || _global).options.poly && (i = L.Util.extend({}, (this || _global).options.poly), o.options.poly = i), (this || _global).options.selectedPathOptions && (e = L.Util.extend({}, (this || _global).options.selectedPathOptions), e.maintainColor && (e.color = o.options.color, e.fillColor = o.options.fillColor), o.options.original = L.extend({}, o.options), o.options.editing = e), o instanceof L.Marker ? (o.editing && o.editing.enable(), o.dragging.enable(), o.on("dragend", (this || _global)._onMarkerDragEnd).on("touchmove", (this || _global)._onTouchMove, this || _global).on("MSPointerMove", (this || _global)._onTouchMove, this || _global).on("touchend", (this || _global)._onMarkerDragEnd, this || _global).on("MSPointerUp", (this || _global)._onMarkerDragEnd, this || _global)) : o.editing.enable();
    },
    _disableLayerEdit: function (t) {
      var e = t.layer || t.target || t;
      e.edited = !1, e.editing && e.editing.disable(), delete e.options.editing, delete e.options.original, (this || _global)._selectedPathOptions && (e instanceof L.Marker ? this._toggleMarkerHighlight(e) : (e.setStyle(e.options.previousOptions), delete e.options.previousOptions)), e instanceof L.Marker ? (e.dragging.disable(), e.off("dragend", (this || _global)._onMarkerDragEnd, this || _global).off("touchmove", (this || _global)._onTouchMove, this || _global).off("MSPointerMove", (this || _global)._onTouchMove, this || _global).off("touchend", (this || _global)._onMarkerDragEnd, this || _global).off("MSPointerUp", (this || _global)._onMarkerDragEnd, this || _global)) : e.editing.disable();
    },
    _onMouseMove: function (t) {
      (this || _global)._tooltip.updatePosition(t.latlng);
    },
    _onMarkerDragEnd: function (t) {
      var e = t.target;
      e.edited = !0, (this || _global)._map.fire(L.Draw.Event.EDITMOVE, {
        layer: e
      });
    },
    _onTouchMove: function (t) {
      var e = t.originalEvent.changedTouches[0],
          i = (this || _global)._map.mouseEventToLayerPoint(e),
          o = (this || _global)._map.layerPointToLatLng(i);

      t.target.setLatLng(o);
    },
    _hasAvailableLayers: function () {
      return 0 !== (this || _global)._featureGroup.getLayers().length;
    }
  }), L.EditToolbar.Delete = L.Handler.extend({
    statics: {
      TYPE: "remove"
    },
    initialize: function (t, e) {
      if (L.Handler.prototype.initialize.call(this || _global, t), L.Util.setOptions(this || _global, e), (this || _global)._deletableLayers = (this || _global).options.featureGroup, !((this || _global)._deletableLayers instanceof L.FeatureGroup)) throw new Error("options.featureGroup must be a L.FeatureGroup");
      (this || _global).type = L.EditToolbar.Delete.TYPE;
      var i = L.version.split(".");
      1 === parseInt(i[0], 10) && parseInt(i[1], 10) >= 2 ? L.EditToolbar.Delete.include(L.Evented.prototype) : L.EditToolbar.Delete.include(L.Mixin.Events);
    },
    enable: function () {
      !(this || _global)._enabled && this._hasAvailableLayers() && (this.fire("enabled", {
        handler: (this || _global).type
      }), (this || _global)._map.fire(L.Draw.Event.DELETESTART, {
        handler: (this || _global).type
      }), L.Handler.prototype.enable.call(this || _global), (this || _global)._deletableLayers.on("layeradd", (this || _global)._enableLayerDelete, this || _global).on("layerremove", (this || _global)._disableLayerDelete, this || _global));
    },
    disable: function () {
      (this || _global)._enabled && ((this || _global)._deletableLayers.off("layeradd", (this || _global)._enableLayerDelete, this || _global).off("layerremove", (this || _global)._disableLayerDelete, this || _global), L.Handler.prototype.disable.call(this || _global), (this || _global)._map.fire(L.Draw.Event.DELETESTOP, {
        handler: (this || _global).type
      }), this.fire("disabled", {
        handler: (this || _global).type
      }));
    },
    addHooks: function () {
      var t = (this || _global)._map;
      t && (t.getContainer().focus(), (this || _global)._deletableLayers.eachLayer((this || _global)._enableLayerDelete, this || _global), (this || _global)._deletedLayers = new L.LayerGroup(), (this || _global)._tooltip = new L.Draw.Tooltip((this || _global)._map), (this || _global)._tooltip.updateContent({
        text: L.drawLocal.edit.handlers.remove.tooltip.text
      }), (this || _global)._map.on("mousemove", (this || _global)._onMouseMove, this || _global));
    },
    removeHooks: function () {
      (this || _global)._map && ((this || _global)._deletableLayers.eachLayer((this || _global)._disableLayerDelete, this || _global), (this || _global)._deletedLayers = null, (this || _global)._tooltip.dispose(), (this || _global)._tooltip = null, (this || _global)._map.off("mousemove", (this || _global)._onMouseMove, this || _global));
    },
    revertLayers: function () {
      (this || _global)._deletedLayers.eachLayer(function (t) {
        (this || _global)._deletableLayers.addLayer(t), t.fire("revert-deleted", {
          layer: t
        });
      }, this || _global);
    },
    save: function () {
      (this || _global)._map.fire(L.Draw.Event.DELETED, {
        layers: (this || _global)._deletedLayers
      });
    },
    removeAllLayers: function () {
      (this || _global)._deletableLayers.eachLayer(function (t) {
        this._removeLayer({
          layer: t
        });
      }, this || _global), this.save();
    },
    _enableLayerDelete: function (t) {
      (t.layer || t.target || t).on("click", (this || _global)._removeLayer, this || _global);
    },
    _disableLayerDelete: function (t) {
      var e = t.layer || t.target || t;
      e.off("click", (this || _global)._removeLayer, this || _global), (this || _global)._deletedLayers.removeLayer(e);
    },
    _removeLayer: function (t) {
      var e = t.layer || t.target || t;
      (this || _global)._deletableLayers.removeLayer(e), (this || _global)._deletedLayers.addLayer(e), e.fire("deleted");
    },
    _onMouseMove: function (t) {
      (this || _global)._tooltip.updatePosition(t.latlng);
    },
    _hasAvailableLayers: function () {
      return 0 !== (this || _global)._deletableLayers.getLayers().length;
    }
  });
}(window, document);
export default {};